import { sdfBuffer_VS } from "./sdfBuffer_VS"
import { sdfBuffer_FS } from "./sdfBuffer_FS"
import { harmonic2D_VS } from "./harmonic2D_VS"
import { harmonic2D_FS } from "./harmonic2D_FS"
import { harmonic3D_VS } from "./harmonic3D_VS"
import { harmonic3D_FS } from "./harmonic3D_FS"
import { default_VS } from "./default_VS"
import { calendar_FS } from "./calendar_FS"
import { calendarSdMap_CS } from "./calendarSdMap_CS"

class shaders {
    public sdfBuffer_VS = sdfBuffer_VS;
    public sdfBuffer_FS = sdfBuffer_FS;
    public harmonic2D_VS = harmonic2D_VS;
    public harmonic2D_FS = harmonic2D_FS;
    public harmonic3D_VS = harmonic3D_VS;
    public harmonic3D_FS = harmonic3D_FS;
    public default_VS = default_VS;
    public calendar_FS = calendar_FS;
    public calendarSdMap_CS = calendarSdMap_CS;
}

const Shaders = new shaders();

export default Shaders;