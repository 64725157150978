import * as Three from "three";

const pi2 = parseFloat((Math.PI * 2).toFixed(6));

export class Spoke {
    public angle: number;
    public length: number;
    public harmonic: number;

    constructor(angle: number, length: number, harmonic: number) {
        this.angle = angle;
        this.length = length;
        this.harmonic = harmonic;
    }
}

class starBuilder {
    public buildBufferPoints(harmonics: number, spokeLength: number, radius: number, hasCenter: boolean, isLoop: boolean): Array<Three.Vector3> {
        const points: Array<number> = [];

        const spokes = this.buildSpokes(harmonics, spokeLength, radius);

        if (hasCenter) {
            points.push(0);
            points.push(0);
            points.push(0);
        }

        spokes.forEach(spoke => {
            points.push(0);
            points.push(Math.sin(spoke.angle) * spoke.length);
            points.push(Math.cos(spoke.angle) * spoke.length);
        });

        if(isLoop){
            if (hasCenter) {
                points.push(points[1]);
                points.push(points[4]);
                points.push(points[5]);
            } else {
                points.push(points[0]);
                points.push(points[1]);
                points.push(points[2]);
            }
        }
        return points;
    }

    public buildBezierStar(harmonics: number, spokeLength: number, radius: number, control: number) {
        const spokes = this.getSpokes();//buildSpokes(harmonics, spokeLength, radius);
        //spokes.push(new Spoke(pi2, spokeLength + radius, 1.0));

        const shape = new Three.Shape();
        for (let curve = 0; curve < spokes.length - 1; curve++) {
            const points = this.getPoints(
                spokes[curve].x,
                spokes[curve].y,
                spokes[curve + 1].x,
                spokes[curve + 1].y,
                spokeLength,
                radius,
                control);
            if (curve === 0)
                shape.moveTo(points[0].x, points[0].y);
            shape.quadraticCurveTo(points[1].x, points[1].y, points[2].x, points[2].y);
        }
        return shape;
    }

    public getPoints(a1: number, l1: number, a2: number, l2: number, spokeLength: number, radius: number, control: number) {
        const points: Array<Three.Vector2> = [];

        let len = l1 * spokeLength + radius;
        points[0] = this.getPoint(a1, len);

        const ang = (a2 - a1) / 2. + a1;
        points[1] = this.getPoint(ang, control);

        len = l2 * spokeLength + radius;
        points[2] = this.getPoint(a2, len);

        return points;
    }

    public getPoint(angle: number, length: number) {
        angle *= Math.PI * 2;
        return new Three.Vector2(Math.cos(angle) * length, Math.sin(angle) * length);
    }


    //public getPoints(a1: number, l1: number, a2: number, l2: number, control: number) {
    //    const points: Array<Three.Vector2> = [];

    //    let len = l1;
    //    points[0] = this.getPoint(a1, len);
    
    //    const ang = (a2 - a1) / 2. + a1;
    //    points[1] = this.getPoint(ang, control);

    //    len = l2;
    //    points[2] = this.getPoint(a2, len);

    //    return points;
    //}

    //public getPoint(angle: number, length: number) {
    //    return new Three.Vector2(Math.cos(angle) * length, Math.sin(angle) * length);
    //}

    public buildSpokes(harmonics: number, spokeLength: number, radius: number): Array<Spoke>{
        const spokes = new Map();
        for (let harmonic = 1; harmonic <= harmonics; harmonic++) {

            const harmonicAngle = 2 * Math.PI / harmonic;
            const length = radius + spokeLength / harmonic;

            for (let spokeNum = 1; spokeNum <= harmonic; spokeNum++) {
                let spokeAngle = parseFloat((harmonicAngle * spokeNum).toFixed(6));
                while (spokeAngle >= pi2)
                    spokeAngle -= pi2;


                if (spokes.has(spokeAngle))
                    continue;
                const spoke = new Spoke(spokeAngle, length, harmonic);
                spokes.set(spokeAngle, spoke);
            }
        }
        const sorted = new Map<number, Spoke>([...spokes].sort());
        return Array.from(sorted.values());
    }

    public getSpokes() {
        const spokes = new Array<Three.Vector2>();

        spokes[0] = new Three.Vector2(0, .8);
        spokes[1] = new Three.Vector2(.111090146, .08888889);
        spokes[2] = new Three.Vector2(.12493663, .1);
        spokes[3] = new Three.Vector2(.14292113, .114285715);
        spokes[4] = new Three.Vector2(.16663523, .13333334);
        spokes[5] = new Three.Vector2(.20005776, .16);
        spokes[6] = new Three.Vector2(.22218029, .08888889);
        spokes[7] = new Three.Vector2(.2500324, .2);
        spokes[8] = new Three.Vector2(.2856831, .114285715);
        spokes[9] = new Three.Vector2(.33327046, .26666668);
        spokes[10] = new Three.Vector2(.37496904, .1);
        spokes[11] = new Three.Vector2(.39995638, .16);
        spokes[12] = new Three.Vector2(.42860427, .114285715);
        spokes[13] = new Three.Vector2(.44451973, .08888889);
        spokes[14] = new Three.Vector2(.5000648, .4);
        spokes[15] = new Three.Vector2(.5556099, .08888889);
        spokes[16] = new Three.Vector2(.5713662, .114285715);
        spokes[17] = new Three.Vector2(.6000141, .16);
        spokes[18] = new Three.Vector2(.62500143, .1);
        spokes[19] = new Three.Vector2(.66670007, .26666668);
        spokes[20] = new Three.Vector2(.71428734, .114285715);
        spokes[21] = new Three.Vector2(.7499381, .2);
        spokes[22] = new Three.Vector2(.7777902, .08888889);
        spokes[23] = new Three.Vector2(.8000719, .16);
        spokes[24] = new Three.Vector2(.8333353, .13333334);
        spokes[25] = new Three.Vector2(.85720855, .114285715);
        spokes[26] = new Three.Vector2(.87503386, .1);
        spokes[27] = new Three.Vector2(.8888803, .08888889);
        spokes[28] = new Three.Vector2(1., .8);

        return spokes;
    }

}

const StarBuilder = new starBuilder();
export default StarBuilder;