import * as Three from "three";
import { IPreRenderAction  } from "../Classes/RenderLoop"

export class AutoRotate implements IPreRenderAction {

    private object3D: Three.Object3D;
    private xSpeed: number;
    private ySpeed: number;
    private zSpeed: number;

    constructor(object3D: Three.Object3D, xSpeed: number, ySpeed: number, zSpeed: number) {
        this.object3D = object3D;
        this.xSpeed = xSpeed;
        this.ySpeed = ySpeed;
        this.zSpeed = zSpeed;
    }

    public onPreRender(frameNumber: number, timeDelta: number) {
        this.object3D.rotation.x -= this.xSpeed * timeDelta / 1000;
        this.object3D.rotation.y -= this.ySpeed * timeDelta / 1000;
        this.object3D.rotation.z -= this.zSpeed * timeDelta / 1000;
    }

}