import { EMRAT} from './src/constants/index'
import { IEphemeris } from "../IEphemeris"
import Body from "./src/classes/Body"
import { kepler } from "./src/utilities/kepler"
import { gPlanCalc, gPlanMoon, gPlanCalc3, get_lp_equinox } from './src/utilities/gplan'
import { julian as Julian } from "./src/utilities/julian"
import { precess } from './src/utilities/precess'

import * as Stella from "../../Classes/Stella"

class MoshierEphemeris implements IEphemeris {

    private _sun = new Body("sun");
    private _moon = new Body("moon");
    private _mercury = new Body("mercury");
    private _venus = new Body("venus");
    private _earth = new Body("earth");
    private _mars = new Body("mars");
    private _jupiter = new Body("jupiter");
    private _saturn = new Body("saturn");
    private _neptune = new Body("neptune");
    private _uranus = new Body("uranus");
    private _pluto = new Body("pluto");

    private _lastPolEarthDate: number;
    private _lastPolEarthCoOrds: Array<number>;
    private _lastRecEarthDate: number;
    private _lastRecEarthCoOrds: Array<number>;

    public getBodiesPolar(julianDate: number) {

        const bodies = new Array<Stella.PolarCoOrd>();
        bodies.push(new Stella.PolarCoOrd(this.getPolarCoOrd(1, this._sun, julianDate)));
        bodies.push(new Stella.PolarCoOrd(this.getPolarCoOrd(1, this._moon, julianDate)));
        bodies.push(new Stella.PolarCoOrd(this.getPolarCoOrd(1, this._mercury, julianDate)));
        bodies.push(new Stella.PolarCoOrd(this.getPolarCoOrd(1, this._venus, julianDate)));
        bodies.push(new Stella.PolarCoOrd(this.getPolarCoOrd(1, this._mars, julianDate)));
        bodies.push(new Stella.PolarCoOrd(this.getPolarCoOrd(1, this._jupiter, julianDate)));
        bodies.push(new Stella.PolarCoOrd(this.getPolarCoOrd(1, this._saturn, julianDate)));
        bodies.push(new Stella.PolarCoOrd(this.getPolarCoOrd(1, this._uranus, julianDate)));
        bodies.push(new Stella.PolarCoOrd(this.getPolarCoOrd(1, this._neptune, julianDate)));

        return bodies;
    }

    public getBodiesRect(julianDate: number) {

        const bodies = new Array<Stella.RectCoOrd>();
        bodies.push(new Stella.RectCoOrd(this.getRectCoOrd(1, this._sun, julianDate)));
        bodies.push(new Stella.RectCoOrd(this.getRectCoOrd(1, this._moon, julianDate)));
        bodies.push(new Stella.RectCoOrd(this.getRectCoOrd(1, this._mercury, julianDate)));
        bodies.push(new Stella.RectCoOrd(this.getRectCoOrd(1, this._venus, julianDate)));
        bodies.push(new Stella.RectCoOrd(this.getRectCoOrd(1, this._mars, julianDate)));
        bodies.push(new Stella.RectCoOrd(this.getRectCoOrd(1, this._jupiter, julianDate)));
        bodies.push(new Stella.RectCoOrd(this.getRectCoOrd(1, this._saturn, julianDate)));
        bodies.push(new Stella.RectCoOrd(this.getRectCoOrd(1, this._uranus, julianDate)));
        bodies.push(new Stella.RectCoOrd(this.getRectCoOrd(1, this._neptune, julianDate)));

        return bodies;
    }


    public getPolarData(center: number, bodyId: number, dates: number[]): Array<Stella.PolarCoOrd>{
        const body:any = this.selectBody(bodyId);
        const data: Array<Stella.PolarCoOrd> = [];

        dates.forEach(date => {
            data.push(new Stella.PolarCoOrd(this.getPolarCoOrd(center, body, date)));
        });
        return data;
    }

    public getRectData(center: number, bodyId: number, dates: number[]): Array<Stella.RectCoOrd> {
        const body: any = this.selectBody(bodyId);
        const data: Array<Stella.RectCoOrd> = [];

        dates.forEach(date => {
            data.push(new Stella.RectCoOrd(this.getRectCoOrd(center, body, date)));
        });
        return data;
    }

    private getPolarCoOrd(center: number, body: any, date: number) :number[] {
        let record: Array<number> = [];

        if (center === 0) { //sun
            if (body.key === "earth") {
                if (this._lastPolEarthDate === date)
                    record = this._lastPolEarthCoOrds;
                else {
                    const rect = this.getRectCoOrd(center, body, date);
                    record = this.toPolar(rect);
                    this._lastPolEarthDate = date;
                    this._lastPolEarthCoOrds = record;
                }
            } else if (body.key === "moon") {
                record = [999, 999, 999];
            } else {
                record = gPlanCalc(date, body.ptable, []);
            }
        }
        else if (center === 1) { //earth
                if (body.key === "moon") {
                    record = this.calcMoon(date);
                } else if (body.key === "earth") {
                    record = [999, 999, 999];
                } else if (body.key === "sun") {
                    const earthRect = this.getRectCoOrd(0, this._earth, date);
                    record = this.toPolar(this.sunRect(earthRect));
                }else {
                    const earthRect = this.getRectCoOrd(0, this._earth, date);
                    const sunRect = this.sunRect(earthRect);
                    let rect = this.toRect(gPlanCalc(date, body.ptable, []));
                    record = this.toPolar(this.toGeocentric(rect, sunRect));
                }
        }
        return record;
    }

    private getRectCoOrd(center: number, body: any, date: number): number[] {
        let record: Array<number> = [];

        if (center === 0) { //sun
                var polar = [];
                if (body.key === "earth") {
                    if (this._lastRecEarthDate === date)
                        record = this._lastRecEarthCoOrds;
                    else {
                        const emb = this.toRect(gPlanCalc3(date, body.ptable, [], 3));
                        record = this.embofs(date, emb);
                        this._lastRecEarthDate = date;
                        this._lastRecEarthCoOrds = record;
                    }
                } else if (body.key === "moon") {
                    record = [0, 0, 0];
                } else {
                    record = this.toRect(gPlanCalc(date, body.ptable, []));
                }
        }
        else if (center === 1) { //earth
            if (body.key === "moon") {
                record = this.toRect(this.calcMoon(date));
            } else if (body.key === "sun") {
                const earthRect = this.getRectCoOrd(0, this._earth, date);
                record = this.sunRect(earthRect);
            } else {
                const earthRect = this.getRectCoOrd(0, this._earth, date);
                const sunRect = this.sunRect(earthRect);
                let rect = this.toRect(gPlanCalc(date, body.ptable, []));
                record = this.toGeocentric(rect, sunRect);
            }
        }
        return record;
    }


    public toJulian(date: Date) {
        const dateObject = {
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1,
            day: date.getUTCDate(),
            hours: date.getUTCHours(),
            minutes: date.getUTCMinutes(),
            seconds: date.getUTCSeconds()
        }

        return Julian.calcJulianDate(dateObject);
    }

    public toRect(polar: number[]){
        const rect: Array<number> = [];
        const lon = polar[0];
        const lat = polar[1];
        const rad = polar[2];

        rect[0] = rad * Math.cos(lat) * Math.cos(lon);
        rect[1] = rad * Math.cos(lat) * Math.sin(lon);
        rect[2] = rad * Math.sin(lat);
        return rect;
    }

    public toPolar(rect: number[]) {
        const x = rect[0];
        const y = rect[1];
        const z = rect[2];
        const polar: Array<number> = [];

        const rad = Math.sqrt(x * x + y * y + z * z);

        if (rad !== 0) {
            polar[0] = Math.atan2(y, x);
            polar[1] = Math.atan(z / Math.sqrt(x * x + y * y));
            polar[2] = rad;
        }
        return polar;
    }


    private sunRect(rect: number[]) {
        const sun = Array<number>();
        sun[0] = rect[0] * -1;
        sun[1] = rect[1] * -1;
        sun[2] = rect[2] * -1;
        return sun;
    }

    private toGeocentric(body: number[], sun: number[]) :number[] {
        const x = body[0] + sun[0];
        const y = body[1] + sun[1];
        const z = body[2] + sun[2];

        return [x, y, z];
    }

    private calcMoon(julianDate: number) {
        var rect = [], pol = [];
        const lpEquinox = get_lp_equinox(julianDate);
        gPlanMoon(julianDate, rect, pol, lpEquinox);
        return pol;
    //    return new BodyPosition(pol[0], pol[1], pol[2]);
    }

    /* Adjust position from Earth-Moon barycenter to Earth
     *
     * J = Julian day number
     * emb = Equatorial rectangular coordinates of EMB.
     * return = Earth's distance to the Sun (au)
     */
    private embofs = (julianDate, ea) => {
        var pm = [], polm = [], ec = []; // double
        var b: number; // double
        var i: number; // int

        /* Compute the vector Moon - Earth.  */
        const lp_equinox = get_lp_equinox(julianDate);
        pm = gPlanMoon(julianDate, pm, polm, lp_equinox); // TODO - investigate how this mutates the data.

        /* Precess the lunar position
         * to ecliptic and equinox of J2000.0
         */
        pm = precess.calc(pm, julianDate, 1);

        /* Adjust the coordinates of the Earth
         */
        var a = 1.0 / (EMRAT + 1.0);
        b = 0.0;
        for (i = 0; i < 3; i++) {
            ec[i] = ea[i] - a * pm[i];
        }
        return ec;
    };


    private selectBody(body: number): Body {
        switch (body) {
        case 0:
            return this._mercury;
        case 1:
            return this._venus;
        case 2:
            return this._earth;
        case 3:
            return this._mars;
        case 4:
            return this._jupiter;
        case 5:
            return this._saturn;
        case 6:
            return this._uranus;
        case 7:
            return this._neptune;
        case 8:
            return this._pluto;
        case 9:
            return this._moon;
        case 10:
            return this._sun;
        case 12:
            return this._earth;
        }
        return undefined;
    }
}

const Ephemeris = new MoshierEphemeris();
export default Ephemeris;