import * as Three from "three";
import { Stats } from "../ThreeExtras/Stats.js";

export interface IPreRenderAction {
    onPreRender(frameNumber: number, timeDelta: number);
}

export interface IPostRenderAction {
    onPostRender(frameNumber: number, timeDelta: number);
}

export class View {
    scene: Three.Scene;
    camera: Three.Camera;

}

export class RenderLoop {
    protected _renderer: Three.WebGLRenderer;
    public set renderer(value: Three.WebGLRenderer) { this._renderer = value; }
    public get renderer(): Three.WebGLRenderer { return this._renderer; }

    protected views: Array<any> = [];
    protected running = false;
    protected stats: Stats = null;

    protected _currentFrame = 0;
    protected _timeDelta: number;
    protected _prevFrameTime = 0;

    protected _preRenderActions: Array<IPreRenderAction> = [];
    protected _postRenderActions: Array<IPostRenderAction> = [];

    public setStatsMode( statsMode: number) {
        if (statsMode === -1) {
            if(this.stats){
                this.renderer.domElement.parentElement.removeChild(this.stats.dom);
                this.stats = undefined;
            }
            return;
        }
        this.stats = new Stats(this.renderer.domElement.parent);
        this.stats.showPanel(statsMode);

        this.renderer.domElement.parentElement.appendChild(this.stats.dom);

        //document.body.appendChild(this.stats.dom);

    }

    public add(scene: Three.Scene, camera: Three.Camera) {
        const newView = { scene: scene, camera: camera };
        this.views.push(newView);
    }

    public start() {
        this.running = true;
        this.update();
        this._prevFrameTime = (performance || Date).now();
        this._currentFrame = 0;
    }

    public stop() {
        this.running = false;
    }

    public dispose() {
        this.stop();
        this.views = [];
        this._preRenderActions = [];
        this._postRenderActions = [];
    }

    public runOnce() {
        this.render();
    }

    public update() {
        const time = (performance || Date).now();
        this._timeDelta = time - this._prevFrameTime;
        this._prevFrameTime = time;

        this.preRender();
        this.render();
        this.postRender();

        if (this.running) {
            requestAnimationFrame(this.update.bind(this));
        }
    }

    public addPreRender(action: IPreRenderAction) {
        this._preRenderActions.push(action);
    }

    public addPostRender(action: IPostRenderAction) {
        this._postRenderActions.push(action);
    }

    protected render() {
        for (const view of this.views) {
            this.renderer.render(view.scene, view.camera);
            if (this.stats)
                this.stats.update();
        }
    }

    protected preRender() {
        for (const action of this._preRenderActions) {
            action.onPreRender(this._currentFrame, this._timeDelta);
        }
    }

    protected postRender() {
        for (const action of this._postRenderActions) {
            action.onPostRender(this._currentFrame, this._timeDelta);
        }
    }
}

enum PlayerState {
    ToStart,
    PlayRev,
    StepRev,
    Pause,
    StepFwd,
    PlayFwd,
    ToEnd
}

enum ButtonState{
    CanMoveToStart = 1,
    CanPlayRev = 2,
    CanStepRev = 4,
    CanPause = 8,
    CanStepFwd = 16,
    CanPlayFwd = 32,
    CanMoveToEnd = 64,
    CanLoop = 128,
    CanBounce = 256,
    CanSlide = 512,
    CanOpenSettings = 1024,
    CanRecord = 2048,
}



export class Player extends RenderLoop {
    public state: PlayerState = PlayerState.Pause;

    private _sliderInput: HTMLInputElement;
    private _sliderMin: number;
    private _sliderMax: number;
    private _sliderFill: HTMLElement;

    private _dotNetHelper: any;

    private _fps: number;
    private _fpsCount: number;

    private _beginTime = (performance || Date).now();
    private _prevSampleTime = this._beginTime;

    private _canRecord: boolean;
    private _prevBtnStates = 0;

    ///////
    private _startFrame: number;
    public set startFrame(value: number) { this._startFrame = value; this.updateButtonStates(); }
    public get startFrame(): number { return this._startFrame; }

    private _endFrame: number;
    public set endFrame(value: number) { this._endFrame = value; this.updateButtonStates(); }
    public get endFrame(): number { return this._endFrame; }

    public set currentFrame(value: number) { this._currentFrame = value; this.updateButtonStates(); }
    public get currentFrame(): number { return this._currentFrame; }

    private _isLooping: boolean;
    public set isLooping(value: boolean) { this._isLooping = value; this.updateButtonStates(); }

    private _isRecording = false;
    public set isRecording(value: boolean) { this._isRecording = value; this.updateButtonStates(); }


    public setSlider(slider: HTMLElement) {
        this._sliderInput = slider.querySelector(".mud-slider-input");
        this._sliderMin = +this._sliderInput.min;
        this._sliderMax = +this._sliderInput.max;
        this._currentFrame = 0;
        this._sliderFill = slider.querySelector(".mud-slider-filled");
        this.updateSlider();
    }

    public setSliderValue(value: number) {
        this.currentFrame = value;
        this.updateSlider();
    }

    public setDotNetHelper(dotNetHelper: any) {
        this._dotNetHelper = dotNetHelper;
    }

    public updateState(state: PlayerState) {
        this.state = state;
        this.updateButtonStates(false);
        if (this._dotNetHelper)
            this._dotNetHelper.invokeMethodAsync("SetState", this.state);
    }

    public update() {
        switch (this.state) {
            case PlayerState.ToStart:
                this._currentFrame = this.startFrame;
                this.updateState(PlayerState.Pause);
                this.updateSlider();
                break;
            case PlayerState.PlayRev:
                this.frameUpdate(-1);
                break;
            case PlayerState.StepRev:
                this.frameUpdate(-1);
                this.updateState(PlayerState.Pause);
                break;
            case PlayerState.Pause:
                this.frameUpdate(0);
                break;
            case PlayerState.StepFwd:
                this.frameUpdate(1);
                this.updateState(PlayerState.Pause);
                break;
            case PlayerState.PlayFwd:
                this.frameUpdate(1);
                break;
            case PlayerState.ToEnd:
                this._currentFrame = this._endFrame;
                this.updateState(PlayerState.Pause);
                this.updateSlider();
                break;
        }
        if (this.running) {
            requestAnimationFrame(this.update.bind(this));
        }
    }

    private frameUpdate(direction: number) {
        const time = (performance || Date).now();
        this._timeDelta = (time - this._prevFrameTime) * direction;
        this._prevFrameTime = time;

        this._fpsCount++;
        if (time >= this._prevSampleTime + 200) {
            this._fps = (this._fpsCount * 200) / (time - this._prevSampleTime);
            this._fpsCount = 0;
            this._prevSampleTime = 0;
        }
        this.currentFrame += direction;

        if (this._isLooping) {
            if (this.currentFrame < this.startFrame)
                this.currentFrame = this.endFrame;
            else if (this.currentFrame > this.endFrame)
                this.currentFrame = this.startFrame;
        }
        else if ((this.currentFrame === this.startFrame || this.currentFrame === this.endFrame) && this.state !== PlayerState.Pause) {
            this.updateState(PlayerState.Pause);
        }

        if (direction !== 0) this.updateSlider();
        this.preRender();
        this.render();
        this.postRender();
    }

    private updateSlider() {
        this._sliderInput.value = this.currentFrame.toString();
        if (this._sliderFill) {
            var fill = 100.0 * (this.currentFrame - this._sliderMin) / (this._sliderMax - this._sliderMin);
            fill = Math.min(Math.max(0, fill), 100);
            this._sliderFill.style["width"] = fill.toString() + "%";
        }
    }

    private updateButtonStates(disable: boolean  = false) {
        var buttonStates = 0;
        if (!disable) {
            if (this.startFrame !== Number.MIN_VALUE && !this._isRecording && this._currentFrame !== this.startFrame)
                buttonStates |= ButtonState.CanMoveToStart;
            if (this._currentFrame !== this.startFrame || this._isLooping)
                buttonStates |= ButtonState.CanPlayRev;
            if (this._currentFrame !== this.startFrame || this._isLooping)
                buttonStates |= ButtonState.CanStepRev;
            buttonStates |= ButtonState.CanPause;
            if (this._currentFrame !== this._endFrame || this._isLooping)
                buttonStates |= ButtonState.CanStepFwd;
            if (this._currentFrame !== this._endFrame || this._isLooping)
                buttonStates |= ButtonState.CanPlayFwd;
            if (this._endFrame !== Number.MAX_VALUE && !this._isRecording && this._currentFrame !== this._endFrame)
                buttonStates |= ButtonState.CanMoveToEnd;
            if (this.startFrame !== Number.MIN_VALUE && this._endFrame !== Number.MAX_VALUE && !this._isRecording)
                buttonStates |= ButtonState.CanLoop;
            if (this.startFrame !== Number.MIN_VALUE && this._endFrame !== Number.MAX_VALUE && !this._isRecording)
                buttonStates |= ButtonState.CanBounce;
            if (this.startFrame !== Number.MIN_VALUE && this._endFrame !== Number.MAX_VALUE && !this._isRecording)
                buttonStates |= ButtonState.CanSlide;
            buttonStates |= ButtonState.CanOpenSettings;
            if (this._canRecord)
                buttonStates |= ButtonState.CanRecord;
        }
        if (this._dotNetHelper && buttonStates !== this._prevBtnStates)
            this._dotNetHelper.invokeMethodAsync("SetButtonStates", buttonStates);
        this._prevBtnStates = buttonStates;
    }
}