import * as Three from "three";
import { GalaxyBase, ecliptic } from "./GalaxyBase";
import Shaders from "../Shaders/Shaders"
import * as Stella from "../Classes/Stella"
import StarBuilder from "../Classes/StarBuilder"
import { Spatial, ISpatialHandler } from "../Classes/Spatial";
import { Text } from "troika-three-text"

enum FindGroup {
    Home,
    Direction,
    CelestialBodies,
    Constellations,
    Stars,
    Cities,
    None = 99
}

const markerRadius = 0.02;
const roseLabels = [
    "N", "NW", "W", "SW", "S", "SE", "E", "NE"
];

export class CompassGL extends GalaxyBase implements ISpatialHandler {
    protected _compassRose: Three.Group;
    protected _needle: Three.Group;
    protected _needleUp: Three.Point;

    protected _needleLightMaterial: Three.Material; 
    protected _needleDarkMaterial: Three.Material; 

    protected _marker: Three.Mesh;
    protected _markerLon = 0;
    protected _markerLat = 0;
    protected _markerAxis: Three.Vector3;
    protected _spatial: Spatial;

    protected _roseLabels: Array<Text> = [];

    protected _fGroup: FindGroup = FindGroup.None;
    protected _fObjectId: number;
    protected _directionElements: HTMLElement;

    //---------------------------------------------------------------------------------------------------
    //constructor

    constructor(canvas: HTMLCanvasElement, timeElement: string, dotNetRef: any) {
        super(canvas, timeElement);

        this._spatial = new Spatial(this as ISpatialHandler, dotNetRef);

        this.updateAstro(new Date());
        this.createScene();
        this.updateBodies();

        this._controls.enablePan = false;
        this._controls.enableRotate = false; //<--------------

        this.createRenderLoop(); // must be done after scene is created
    }

    //---------------------------------------------------------------------------------------------------
    //public Methods

    public async init() {
        await this._spatial.init();
    }

    public async setFind(fParams: object) {
        this._fGroup = fParams["group"];
        if (fParams["objectId"] !== undefined)
            this._fObjectId = fParams["objectId"];

         switch (this._fGroup) {
            case FindGroup.None:
                break;
            case FindGroup.Home:
                this._compassRose.visible = false;
                this._needle.visible = false;
                break;
            case FindGroup.Direction:
                this._directionElements = document.getElementById("direction_text");
                this._compassRose.visible = true;
                this._needle.visible = false;
                break;
            case FindGroup.CelestialBodies:
                this._needleLightMaterial.color.setHex(Stella.bodyColors[this._fObjectId]);
                this._needleDarkMaterial.color.setHex(Stella.bodyColors[this._fObjectId]);
                this._needleDarkMaterial.color.lerp(new Three.Color(0x0000), 0.6);
                this._needle.visible = true;
                this._compassRose.visible = false;
                break;
            default:
                this._needle.visible = true;
                this._compassRose.visible = false;
                break;
        }
    }

    public setManualControl() {
        this._controls.enableRotate = false;
        this._controls.enablePan = false;
        this._controls.enableZoom = true;
    }

    //---------------------------------------------------------------------------------------------------
    //protected Methods

    protected initialCameraPosition() {
        var ratio = 2 * Math.tan((this._camera.fov * Math.PI / 180) / 2);
        if (this._camera.aspectRatio > 1.0)
            ratio *= this._camera.aspectRatio;

        var initialDist = (Stella.starSize / ratio) / .95;

        this._camera.position.set(0, 0, initialDist);
        this._camera.up.set(0, 1, 0);
    }

    protected createScene() {
        super.createScene();

        this.addCompassRose();
        this.addNeedle();
        this.addMarker();
    }

    protected updateBodies() {
        super.updateBodies();
        this.positionMarker();
        if (this._fGroup === FindGroup.CelestialBodies) {
            this.positionNeedle();
        }
    }

    private makeRoseSpoke(angle: number, scale: number, lightColor: number, darkColor: number): Three.group {
        const vertices = [
            0, 5, 0,
            0.1, 0, 0,
            0, 0, 0.5,
            -0.1, 0, 0,
            0, 0, -0.5
        ];

        const lightIndices = [
            0, 3, 2,
            0, 1, 4
        ];

        const darkIndices = [
            0, 2, 1,
            0, 4, 3
        ];


        const lightGeometry = new Three.BufferGeometry();
        lightGeometry.setIndex(lightIndices);
        lightGeometry.setAttribute("position", new Three.Float32BufferAttribute(vertices, 3));
        const light = new Three.Mesh(lightGeometry, new Three.MeshBasicMaterial({ color: lightColor }));

        const darkGeometry = new Three.BufferGeometry();
        darkGeometry.setIndex(darkIndices);
        darkGeometry.setAttribute("position", new Three.Float32BufferAttribute(vertices, 3));
        const dark = new Three.Mesh(darkGeometry, new Three.MeshBasicMaterial({ color: darkColor }));

        const spoke = new Three.Group();
        spoke.add(light);
        spoke.add(dark);
        spoke.scale.set(scale, scale, scale);
        spoke.rotateX(angle * Math.PI / 180);
        spoke.translateY(1.3);

        return spoke;
    }

    private makeRoseLabel(angle: number, index: number, scale: number): Text {
        const text = new Text();
        text.fontSize = 0.75;
        text.anchorX = "center";
        text.anchorY = "middle";
        text.color = 0xaaaaaa;
        text.rotateX(angle * Math.PI / 180);
        text.translateY(1.3 + 5.5 * scale);
        text.scale.set(scale, scale, scale);
        text.text = roseLabels[index];
        this._roseLabels[index] = text;
        return text;
    }

    private addCompassRose() {
        const light = 0xfbb037;
        const dark = 0x965e03;
        const rose = new Three.Group();


        for (var i = 0; i < 4; i++) {
            rose.add(this.makeRoseSpoke(i * 90, 1, light, dark));
            rose.add(this.makeRoseLabel(i * 90, i * 2, 1));
            rose.add(this.makeRoseSpoke(i * 90 + 45, 0.6, light, dark));
            rose.add(this.makeRoseLabel(i * 90 + 45, i * 2 + 1, 0.6));
            rose.add(this.makeRoseSpoke(i * 90 + 22.5, 0.3, light, dark));
            rose.add(this.makeRoseSpoke(i * 90 + 67.5, 0.3, light, dark));
        }

        const geometry = new Three.TorusGeometry(1.3, 0.1, 4, 100);
        const material = new Three.MeshBasicMaterial({ color: dark });
        const torus = new Three.Mesh(geometry, material);
        torus.rotateY(Math.PI / 2);
        rose.add(torus);

        this._compassRose = rose;
        this._compassRose.name = "_compassRose";
        this._celestial.add(rose);
        this._compassRose.visible = false;
    }

    private addMarker() {
        const marker = new Three.Mesh(
            new Three.SphereGeometry(markerRadius, 20, 10),
            new Three.MeshBasicMaterial({ color: 0xff7f00 })
        );

        this._marker = marker;
        this._celestial.add(marker);
    }

    private makeNeedleSpoke(): Three.group {
        const vertices = [
            0, 0, 5,
            0, 0.1, 0,
            0.5, 0, 0,
            0, -0.1, 0,
            -0.5, 0, 0
        ];

        const lightIndices = [
            0, 3, 2,
            0, 1, 4
        ];

        const darkIndices = [
            0, 2, 1,
            0, 4, 3
        ];


        const lightGeometry = new Three.BufferGeometry();
        lightGeometry.setIndex(lightIndices);
        lightGeometry.setAttribute("position", new Three.Float32BufferAttribute(vertices, 3));
        const light = new Three.Mesh(lightGeometry, this._needleLightMaterial);

        const darkGeometry = new Three.BufferGeometry();
        darkGeometry.setIndex(darkIndices);
        darkGeometry.setAttribute("position", new Three.Float32BufferAttribute(vertices, 3));
        const dark = new Three.Mesh(darkGeometry, this._needleDarkMaterial);

        const spoke = new Three.Group();
        spoke.add(light);
        spoke.add(dark);
        spoke.translateZ(1.3);

        return spoke;
    }



    private addNeedle() {

        var needle = new Three.Group();
        this._needleLightMaterial = new Three.MeshBasicMaterial({ color: 0xfbb037 });
        this._needleDarkMaterial = new Three.MeshBasicMaterial({ color: 0x965e03 });
        const pointer = this.makeNeedleSpoke();
        needle.add(pointer);

        const torusGeometry = new Three.TorusGeometry(1.3, 0.1, 4, 100);
        const torus = new Three.Mesh(torusGeometry, this._needleDarkMaterial);
        torus.rotateX(Math.PI / 2);
        needle.add(torus);

        const arcGeometry = new Three.TorusGeometry(1.3, 0.05, 4, 100, Math.PI);
        const arc = new Three.Mesh(arcGeometry, this._needleDarkMaterial);
        needle.add(arc);

        this._needle = needle;
        this._needle.name = "_needle";
        this._scene.add(needle);
        this._needle.visible = false;
    }

    private positionNeedle() {
        const rect = Stella.toRect(Stella.solar[this._fObjectId]);
        const lookAt = new Three.Vector3(
            rect.X * Stella.solarDistance,
            rect.Z * Stella.solarDistance,
            rect.Y * Stella.solarDistance);
        this._ecliptic.localToWorld(lookAt);

        const up = new Three.Vector3();
        this._celestial.localToWorld(up.copy(this._markerAxis));
        this._needle.up = up;
        this._needle.lookAt(lookAt);

    }

    private positionMarker() {
        const mRect = Stella.polarToRect(
            this._markerLon + this._earthRotation + Math.PI / 2, // rotate 90deg to align texture
            this._markerLat,
            1
        );
        this._markerAxis = new Three.Vector3(mRect.x, mRect.y, mRect.z);
        this._marker.position.set(this._markerAxis.x, this._markerAxis.y, this._markerAxis.z);

    }

    private getAngle(v1: Three.Vector3, v2: Three.Vector3) {
        const dx = v1.x - v2.x;
        const dz = v1.z - v2.z;
        return Math.atan2(dx, dz);
    }


    //---------------------------------------------------------------------------------------------------
    //Dispose

    //bug: Calling super.dispose() causes a  '_INVALID_OPERATION: Texture is immutable.' warning when using Tronkia-Three text

    public dispose() {
        this._spatial.dispose();
        //this._roseLabels.forEach(value => {
        //    value.material.dispose();
        //    value.dispose();
        //});
        super.dispose();
    }

    //---------------------------------------------------------------------------------------------------
    //Event Listener

    public onPreRender(frameNumber: number, timeDelta: number) {
        super.onPreRender(frameNumber, timeDelta);
        const up = this._camera.localToWorld(new Three.Vector3(0, 1, 0));
        //if (this._fGroup === FindGroup.CelestialBodies) {
        //    this.positionNeedle();
        //}
        if (this._fGroup === FindGroup.Direction) {
            this._roseLabels.forEach(value => {
                value.lookAt(this._camera.position);
            });
        }

    }

    public onLocationChanged(coOrds: GeoLocationCoordinates): void {
        this._markerLon = Stella.degToRad(coOrds.longitude);
        this._markerLat = Stella.degToRad(coOrds.latitude);
        const mRect = Stella.polarToRect(
            this._markerLon + this._earthRotation + Math.PI / 2, // rotate 90deg to align texture
            this._markerLat,
            1
        );
        this._markerAxis = new Three.Vector3(mRect.x, mRect.y, mRect.z);

        const cRect = Stella.polarToRect(
            this._markerLon + this._earthRotation,
            0,
            1
        );

        const dist = this._camera.position.length();

        const markerEuler =
            new Three.Euler(this._markerLat, - this._markerLon - Math.PI / 2 - this._earthRotation, 0, "XYZ");
        const markerMatrix = new Three.Matrix4().makeRotationFromEuler(markerEuler);


        this._markerAxis.normalize();

        this._marker.position.set(this._markerAxis.x, this._markerAxis.y, this._markerAxis.z);

        this._compassRose.setRotationFromAxisAngle(new Three.Vector3(0, 1, 0), this._markerLon + this._earthRotation);
        this._compassRose.rotateZ(this._markerLat);

        this._galaxy.setRotationFromMatrix(markerMatrix);
        this._nightGalaxy.setRotationFromMatrix(markerMatrix);
        this._galaxy.updateMatrixWorld();
        this.positionNeedle();
    }

    public onOrientationChanged(quaternion: Three.Quaternion, direction: number): void {
        if (this._fGroup === FindGroup.Direction)
            this._directionElements.innerText = direction.toFixed(1) + "\xB0";
        this._scene.quaternion.set(quaternion.x, quaternion.y, quaternion.z, quaternion.w).invert();
        this._nightScene.quaternion.set(quaternion.x, quaternion.y, quaternion.z, quaternion.w).invert();
    }
}
