import * as Three from "three";
import { Spatial, ISpatialHandler } from "../Classes/Spatial";


export class BrowserTest implements ISpatialHandler {

    private _spatial: Spatial;

    private _reAlpha: HTMLElement;
    private _reBeta: HTMLElement;
    private _reGamma: HTMLElement;
    private _reAbsolute: HTMLElement;
    private _abAlpha: HTMLElement;
    private _abBeta: HTMLElement;
    private _abGamma: HTMLElement;
    private _abTrueNorth: boolean;

    private _longitude: HTMLElement;
    private _latitude: HTMLElement;
    private _screen: HTMLElement;


    constructor(dotNetRef: any) {
        this._spatial = new Spatial(this as ISpatialHandler, dotNetRef);
    }

    public async initSpatial() {
        await this._spatial.init();
    }

    public setRelativeElements(alpha: HTMLElement, beta: HTMLElement, gamma: HTMLElement, absolute: HTMLElement) {
        this._reAlpha = alpha;
        this._reBeta = beta;
        this._reGamma = gamma;
        this._reAbsolute = absolute;
    }

    public setAbsoluteElements(alpha: HTMLElement, beta: HTMLElement, gamma: HTMLElement, trueNorth: boolean) {
        this._abAlpha = alpha;
        this._abBeta = beta;
        this._abGamma = gamma;
        this._abTrueNorth = trueNorth;
    }

    public setLonLatElements(lon: HTMLElement, lat: HTMLElement, screen: HTMLElement) {
        this._longitude = lon;
        this._latitude = lat;
        this._screen = screen;
    }


    public onRelativeChanged(alpha: number, beta: number, gamma: number, absolute: boolean) {
        this._reAlpha.innerText = alpha.toFixed(4);
        this._reBeta.innerText = beta.toFixed(4);
        this._reGamma.innerText = gamma.toFixed(4);
        this._reAbsolute.innerText = absolute ? "true" : "false";
    }

    public onAbsoluteChanged(alpha: number, beta: number, gamma: number) {
        this._abAlpha.innerText = alpha.toFixed(4);
        this._abBeta.innerText = beta.toFixed(4);
        this._abGamma.innerText = gamma.toFixed(4);
    }

    public onLocationChanged(coOrds: GeoLocationCoordinates) {

    }

    public onOrientationChanged(quaternion: Three.Quaternion) : void {
    }

}