export var ObjectRef = ObjectRef || {};

ObjectRef.objRefs = {};
ObjectRef.objRefId = 0;
ObjectRef.objRefKey = "__jsObjRefId";
ObjectRef.storeObjRef = function (obj) {
    const id = ObjectRef.objRefId++;
    ObjectRef.objRefs[id] = obj;
    const objRef = {};
    objRef[ObjectRef.objRefKey] = id;
    return objRef;
}
ObjectRef.removeObjectRef = function (id) {
    delete ObjectRef.objRefs[id];
}

DotNet.attachReviver(function (key, value) {
    if (value &&
        typeof value === "object" &&
        value.hasOwnProperty(ObjectRef.objRefKey) &&
        typeof value[ObjectRef.objRefKey] === "number") {
        const id = value[ObjectRef.objRefKey];
        if (!(id in ObjectRef.objRefs)) {
            throw new Error("The JS object reference doesn't exist: " + id);
        }
        const instance = ObjectRef.objRefs[id];
        return instance;
    } else {
        return value;
    }
});

