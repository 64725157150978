import * as Three from "three";

export const solarDistance = 23455.295;
export const galaxyImage = "/images/starmap_E_2020_4k_c20_b-10.jpg";
export const bodies = 9;
export const initialDist = 18;
export const fov = 60;
export const minZoom = 2;
export const maxZoom = 30;
export const starSize = 15;
export const planetSize = 0.012;
export const magnify = 4;
export const sunSpriteScale = 1744;


export class PolarCoOrd {
    public Lon: number;
    public Lat: number;
    public Rad: number;

    constructor(coords: number[]) {
        this.Lon = coords[0];
        this.Lat = coords[1];
        this.Rad = coords[2];
    }

}

export class RectCoOrd {
    public X: number;
    public Y: number;
    public Z: number;

    constructor(coords: number[]) {
        this.X = coords[0];
        this.Y = coords[1];
        this.Z = coords[2];
    }
}


export const starDims = [
    0.99,  //spoke length
    0.2,    //inner radius
    0.15   //bezier control
];

export const bodyNames = [
    "Sun",
    "Moon",
    "Mercury",
    "Venus",
    "Mars",
    "Jupiter",
    "Saturn",
    "Uranus",
    "Neptune"
];

export const bodyColors = [
    0xFFE599,   //sun
    0x4f4f4f,   //moon
    0xFFFF00,   //mercury
    0xFF00FF,   //venus
    0xFF0000,   //mars
    0xFF7700,   //jupiter
    0x00BC00,   //saturn
    0x0000FF,   //uranus
    0x00FFE1,   //neptune
    0xC9B061    //ring
];

export const colors = [
    new Three.Vector4(1, 0.898, .6, 1),       //sun
    new Three.Vector4(.3, .3, .3, 1),       //moon
    new Three.Vector4(1, 1, 0, 1),          //mercury
    new Three.Vector4(1, 0, 1, 1),          //venus
    new Three.Vector4(1, 0, 0, 1),          //mars
    new Three.Vector4(1, .467, 0, 1),       //jupiter
    new Three.Vector4(0, .737, 0, 1),       //saturn
    new Three.Vector4(0, 0, 1, 1),          //uranus
    new Three.Vector4(0, 1, .882, 1),       //neptune
new Three.Vector4(.788, .690, .380, 1)      //ring
];



export const bodyRadius = [
    109,        //sun
    0.258,      //moon
    0.383,      //mercury
    0.949,      //venus
    0.532,      //mars
    10.973,     //jupiter
    9.140,      //saturn
    3.98,       //uranus
    3.865       //neptune
];

export const solar = [
    new Three.Vector4(0, 0, 0, 1),
    new Three.Vector4(0, 0, 0, 1),
    new Three.Vector4(0, 0, 0, 1),
    new Three.Vector4(0, 0, 0, 1),
    new Three.Vector4(0, 0, 0, 1),
    new Three.Vector4(0, 0, 0, 1),
    new Three.Vector4(0, 0, 0, 1),
    new Three.Vector4(0, 0, 0, 1),
    new Three.Vector4(0, 0, 0, 1)
];

export const iris = [
    1.0,
    0.3,
    0.18,
    0.01,
    0.03
];

export const effects = [
    1.0,        //ShowOutline
    0,       //OutlineColor
    //-0.5,       //OutlineColor
    0.005,      //OutlineWidth
    1.0,        //ShowEdgeFill
    0.0,        //EdgeFillColor
    15.0,       //EdgeFillWidth
    1.0,        //ShowRadiusFill
    0.0,        //RadiusFillColor
    80.0,       //RadiusFillWidth
    0.0,        //RippleAdvance
    1.0,        //ShowOuterRing
    1.0,        //ShowInnerRing
    //    0.0,
    //    0.0,
    //    0.0,
    //    0.0
];




export function toRect(polar: Three.Vector4): RectCoOrd {
    const x = polar.z * Math.cos(polar.y) * Math.cos(polar.x);
    const y = polar.z * Math.cos(polar.y) * Math.sin(polar.x);
    const z = polar.z * Math.sin(polar.y);
    return new RectCoOrd([x, y, z]);
}

export function polarToRect (lon: number, lat: number, rad: number) {
    return {
        x: rad * Math.cos(lat) * Math.sin(lon),
        y: rad * Math.sin(lat),
        z: rad * Math.cos(lat) * Math.cos(lon)
    }
};

export function rectToPolar(x: number, y: number, z: number) {
    const polar = {
        lon: 0,
        lat: 0,
        rad: 0
    }

    const rad = Math.sqrt(x * x + y * y + z * z);
    if (rad !== 0) {
        polar.lon = Math.atan2(y, x),
        polar.lat = Math.atan(z / Math.sqrt(x * x + y * y));
        polar.rad = rad;
    }
    return polar;
}

export function hmsToDeg(hrs: number, min: number = 0, sec: number = 0) {
    const deg = hrs + min / 60 + sec / 3500;
    return deg * 15;
}

export function dmsToDeg(deg: number, min: number = 0, sec: number = 0) {
    return deg + min / 60 + sec / 3500;
}

export function degToRad(deg: number) {
    return deg * Math.PI / 180;
}

