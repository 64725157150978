import * as Three from "three";
import { OrbitControls } from "../ThreeExtras/OrbitControls.js";
import { RenderLoop, IPreRenderAction, IPostRenderAction } from "../Classes/RenderLoop"
import { ResizeHandler, IResizeAction } from "../Classes/ResizeHandler"
import Ephemeris from "../Ephemeris/Moshier/MoshierEphemeris"
import * as Stella from "../Classes/Stella"
import DateTimeString from "../Classes/DateTimeString"

const galaxyImage = "/images/starmap_E_2020_4k_c20_b-10.jpg";
const constellationImage = "/images/constellation_figures_4k.jpg";
const galacticRadius = 1000000;
export const ecliptic = 0.40910518;


export class GalaxyBase implements IPreRenderAction, IPostRenderAction, IResizeAction {
    protected _isWebGL2: boolean;
    protected _canvas: HTMLCanvasElement;
    protected _renderer: Three.WebGLRenderer;
    protected _scene: Three.Scene;
    protected _nightScene: Three.Scene;
    protected _camera: Three.PerspectiveCamera;
    protected _controls: OrbitControls;

    protected _renderLoop: RenderLoop;

    protected _background: Three.Mesh;
    protected _earth: Three.Mesh;
    protected _earthLights: Three.Mesh;
    protected _bodies: Array<Three.Object3D> = [];
    protected _sunLight: Three.PointLight;
    protected _nightLight: Three.PointLight;

    protected _celestial: Three.Group;
    protected _ecliptic: Three.Group;
    protected _nightEcliptic: Three.Group;
    protected _galaxy: Three.Mesh;
    protected _nightGalaxy: Three.Group;

    protected _lastUpdate = 0;
    protected _currentMinute = -1;
    protected _timeElement: HTMLElement;

    protected _earthRotation: number;


    protected _resizer: ResizeHandler;

    constructor(canvas: HTMLCanvasElement, timeElement: string) {
        this._timeElement = document.getElementById(timeElement);
        this._canvas = canvas;
        this._resizer = new ResizeHandler(this._canvas);
        this._resizer.addAction(this);

        this.createRenderer();
        this.createCamera();
        this.initialCameraPosition();
        this.createOrbitalControls();
    }

    protected createRenderer() {
        const desiredWidth = this._canvas.clientWidth;
        const desiredHeight = this._canvas.clientHeight;

        const gl = this._canvas.getContext("webgl2");
        var renderer;
        if (!gl) {
            renderer = new Three.WebGL1Renderer({ canvas: this._canvas, antialias: true });
            this._isWebGL2 = false;
        }
        else {
            renderer = new Three.WebGLRenderer({ canvas: this._canvas, antialias: true });
            this._isWebGL2 = true;
        }

        var dpr = window.devicePixelRatio;
        renderer.setPixelRatio(dpr);
        renderer.setSize(desiredWidth, desiredHeight);
        renderer.outputEncoding = Three.sRGBEncoding;
        renderer.autoClear = false;
        this._renderer = renderer;
    }

    protected createCamera() {
        const width = this._canvas.clientWidth;
        const height = this._canvas.clientHeight;
        const aspectRatio = width / height;
        const camera = new Three.PerspectiveCamera(Stella.fov, aspectRatio, .01, 1000000000);

        this._camera = camera;
    }

    protected initialCameraPosition() {
    }

    protected setManualControl() {

    }

    protected createOrbitalControls() {
        const control = new OrbitControls(this._camera, this._renderer.domElement);
        control.minDistance = Stella.minZoom;
        control.maxDistance = Stella.maxZoom;
        this._controls = control;
        this.setManualControl();
    }

    protected createRenderLoop() {
        this._renderLoop = new RenderLoop();
        this._renderLoop.renderer = this._renderer;
        this._renderLoop.setStatsMode(-1);
        this._renderLoop.add(this._scene, this._camera);
        this._renderLoop.addPreRender(this);
        this._renderLoop.addPostRender(this);
        this._renderLoop.start();
    }

    protected createScene() {
        this._scene = new Three.Scene();
        this._scene.name = "_scene";
        const light = new Three.AmbientLight(0x040404);
        light.name = "sun light";
        this._scene.add(light);

        this._galaxy = new Three.Group();
        this._galaxy.name = "_galaxy";
        this._scene.add(this._galaxy);

        this._nightScene = new Three.Scene();
        this._nightScene.name = "_nightScene";
        this._nightGalaxy = new Three.Group();
        this._nightGalaxy.name = "_nightGalaxy";
        this._nightScene.add(this._nightGalaxy);

        this._celestial = new Three.Group();
        this._celestial.name = "_celestial";
        this._galaxy.add(this._celestial);

        this._ecliptic = new Three.Group();
        this._ecliptic.name = "_ecliptic";
        this._ecliptic.rotateX(ecliptic);
        this._galaxy.add(this._ecliptic);

        this._nightEcliptic = new Three.Group();
        this._nightEcliptic.name = "_nightEcliptic";
        this._nightEcliptic.rotateX(ecliptic);
        this._nightGalaxy.add(this._nightEcliptic);

        this.addBackground();
        this.addEarth();
        this.addMoon();
        this.addSun();
        this.addPlanets();

    }

    protected addBackground() {
        let loader = new Three.TextureLoader();
        let texture = loader.load(galaxyImage);
        //let texture = loader.load(constellationImage);
        texture.minFilter = Three.LinearFilter;

        texture.encoding = Three.sRGBEncoding;

        let sphere = new Three.SphereGeometry(galacticRadius, 100, 100);
        let sphereMesh = new Three.Mesh(sphere, new Three.MeshBasicMaterial(
            {
                map: texture,
                side: Three.BackSide
            }));
        sphereMesh.rotateY(Math.PI);
        this._scene.add(sphereMesh);
        this._background = sphereMesh;
    }

    protected addEarth() {
        const loader = new Three.TextureLoader();

        const earth = new Three.Mesh(
            new Three.SphereGeometry(1, 32, 32),
            new Three.MeshPhongMaterial({
                map: loader.load("images/land_ocean_ice.jpg"),
                bumpMap: loader.load("images/earth_bump.jpg"),
                bumpScale: 0.005,
                specularMap: loader.load("images/water.png"),
                specular: new Three.Color(0x707070),
            })
        );
        this._earth = earth;
        this._earth.name = "_earth";
        this._celestial.add(earth);

        const earthLights = new Three.Mesh(
            new Three.SphereGeometry(1.01, 32, 32),
            new Three.MeshLambertMaterial({
                map: loader.load("images/earth_lights.gif"),
                blending: Three.AdditiveBlending,
            })
        );
        this._earthLights = earthLights;
        this._earthLights.name = "_earthLights";
        this._nightGalaxy.add(earthLights);
    }

    protected addMoon() {
        const loader = new Three.TextureLoader();
        const moon = new Three.Mesh(
            new Three.SphereGeometry(0.258, 32, 32),
            new Three.MeshPhongMaterial({
                map: loader.load("images/moon_wrap.jpg"),
            })
        );
        moon.scale.set(Stella.magnify, Stella.magnify, Stella.magnify);
        moon.name = "moon";
        this._ecliptic.add(moon);
        this._bodies[1] = moon;
    }

    protected addSun() {
        const sunLight = new Three.PointLight(0xffffff, 1, 0, 2);
        this._sunLight = sunLight;
        this._ecliptic.add(sunLight);

        const nightLight = new Three.PointLight(0xFBB94F, 1, 0, 2);
        this._nightLight = nightLight;
        this._nightEcliptic.add(nightLight);


        const texture = new Three.TextureLoader().load("images/sun.png");
        var sprite = new Three.Sprite(new Three.SpriteMaterial({
            map: texture,
            color: 0xffffff
        }));
        sprite.scale.set(Stella.magnify * Stella.sunSpriteScale, Stella.magnify * Stella.sunSpriteScale, 1);
        this._ecliptic.add(sprite);
        this._bodies[0] = sprite;
    }

    protected addPlanets() {
        const texture = new Three.TextureLoader().load("images/planet.png");

        for (var planet = 2; planet < 9; planet++) {
            var sprite = new Three.Sprite(new Three.SpriteMaterial({
                map: texture,
                color: Stella.bodyColors[planet],
                sizeAttenuation: false
            }));
            sprite.scale.set(Stella.planetSize, Stella.planetSize, 1);
            this._ecliptic.add(sprite);
            this._bodies[planet] = sprite;
        }
    }

    protected updateAstro(date: Date) {
        const jd = Ephemeris.toJulian(date);

        var positions = Ephemeris.getBodiesPolar(jd);
        for (let body = 0; body < Stella.bodies; body++) {
            Stella.solar[body].x = (positions[body].Lon) * -1;
            Stella.solar[body].y = positions[body].Lat;
            Stella.solar[body].z = positions[body].Rad;
        }
    }

    protected updateBodies() {
        const date = new Date();
        const utc = new Date(date.getUTCFullYear(), date.getUTCMonth(),
            date.getUTCDate(), date.getUTCHours(),
            date.getUTCMinutes(), date.getUTCSeconds());

        this.updateAstro(date);

        for (let body = 0; body < Stella.bodies; body++) {
            const rect = Stella.toRect(Stella.solar[body]);
            const x = rect.X * Stella.solarDistance;
            const y = rect.Z * Stella.solarDistance;
            const z = rect.Y * Stella.solarDistance;
            this._bodies[body].position.set(x, y, z);
        }

        this._sunLight.position.set(
            this._bodies[0].position.x,
            this._bodies[0].position.y,
            this._bodies[0].position.z);

        this._nightLight.position.set(
            -this._bodies[0].position.x,
            -this._bodies[0].position.y,
            -this._bodies[0].position.z);

        this._earthRotation = -Stella.solar[0].x +
            Math.PI +
            Stella.degToRad(Stella.hmsToDeg(utc.getHours(), utc.getMinutes(), utc.getSeconds()));
        this._earth.setRotationFromAxisAngle(new Three.Vector3(0, 1, 0), this._earthRotation);
        this._earthLights.setRotationFromAxisAngle(new Three.Vector3(0, 1, 0), this._earthRotation);
        this._lastUpdate = Date.now();
    }

    //---------------------------------------------------------------------------------------------------
    //Dispose

    protected meshDispose(mesh: Three.Mesh) {
        mesh.geometry.dispose();
        if (mesh.material.map)
            mesh.material.map.dispose();
        if (mesh.material.bumpMap)
            mesh.material.bumpMap.dispose();
        if (mesh.material.specularMap)
            mesh.material.specularMap.dispose();
        mesh.material.dispose();
    }

    protected spriteDispose(sprite: Three.Sprite) {
        if (sprite.material.map)
            sprite.material.map.dispose();
        sprite.material.dispose();
    }

    public dispose() {
        for (var planet = 2; planet < 9; planet++) {
            this.spriteDispose(this._bodies[planet]); // planets
        }
        this.spriteDispose(this._bodies[0]); // sun
        this.meshDispose(this._bodies[1]); // moon
        this.meshDispose(this._earth);
        this.meshDispose(this._earthLights);
        this.meshDispose(this._background);

        this._renderer.dispose();
        this._renderLoop.dispose();
        this._renderer.dispose();
    }


    //---------------------------------------------------------------------------------------------------
    //Event Listener

    public onResize(width: number, height: number) {
        this._camera.aspect = width / height;
        this._camera.updateProjectionMatrix();
        this._renderer.setSize(width, height, false);
        this._renderer.render(this._scene, this._camera);
        this._renderer.render(this._nightScene, this._camera);
    }

    public onPreRender(frameNumber: number, timeDelta: number) {
        const date = new Date();
        if (date.getMinutes() !== this._currentMinute) {
            this._currentMinute = date.getMinutes();
            this._timeElement.innerText = DateTimeString.get(date);
        }
        if (date.getTime() - this._lastUpdate > 10000 || this._lastUpdate === 0) {
            this.updateBodies();
        }
    }

    public onPostRender(frameNumber: number, timeDelta: number) {
        this._renderer.render(this._nightScene, this._camera);
    }

}
