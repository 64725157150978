export const harmonic3D_FS = `
#define PACK_RANGE 1.0

#define LON x
#define LAT y
#define DIST z
#define SHOW w

#define ShowOutline 0
#define OutlineWeight 1
#define OutlineWidth 2
#define ShowEdgeFill 3
#define EdgeFillColor 4
#define EdgeFillWidth 5
#define ShowRadiusFill 6
#define RadiusFillColor 7
#define RadiusFillWidth 8
#define RippleAdvance 9
#define ShowOuterRing 10
#define ShowInnerRing 11

uniform sampler2D uBuffer;
uniform vec4 uResolution;
uniform vec4 uColors[10];
uniform vec4 uSolar[9];
uniform float uEffects[12];
uniform uint uIndex;

in vec2 vUv; 

vec2 textureSize;
float dists[9];


float map(float value, float min1, float max1, float min2, float max2) 
{
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

 float unpack(vec4 packedVal)
 {
    float scaled =  dot(packedVal, vec4(1.0, 1.0 / 255.0, 1.0 / 65025.0, 1.0 / 16581375.0));

     return map(scaled, 0., 1., -PACK_RANGE, PACK_RANGE);
 }

vec2 rotate(vec2 v, float a) {
	float s = sin(a);
	float c = cos(a);
	mat2 m = mat2(c, -s, s, c);
	return m * (v - 0.5) + 0.5;
}

float texture2DLinear( sampler2D texSampler, vec2 uv) {
    vec2 pixelOff = vec2(0.5,0.5)/textureSize;

    float tl = unpack(texture(texSampler, uv + vec2(-pixelOff.x,-pixelOff.y)));
    float tr = unpack(texture(texSampler, uv + vec2(pixelOff.x,-pixelOff.y)));
    float bl = unpack(texture(texSampler, uv + vec2(-pixelOff.x,pixelOff.y)));
    float br = unpack(texture(texSampler, uv + vec2(pixelOff.x,pixelOff.y)));

    vec2 f = fract( (uv.xy-pixelOff) * textureSize );
    float tA = mix( tl, tr, f.x );
    float tB = mix( bl, br, f.x );
    return mix( tA, tB, f.y );
}

float getClosest(vec2 p)
{
    float closest = 10000.;
    for(int i = 0; i < 9; i++)
    {
        float sd = uSolar[i].SHOW == 0.
            ? 10000.
            : texture2DLinear(uBuffer,rotate(p, uSolar[i].LON - uSolar[uIndex].LON)) * 2.;
        dists[i] = sd;
        if(sd < closest)
            closest = sd;
    }
    return closest;
}


vec4 innerColor(float closest)
{
    vec4 edgeColor = vec4(0,0,0,0);
    vec4 radiusColor = vec4(0,0,0,0);
    vec4 color = vec4(0,0,0,0.);

    for (int i = 0; i < 9; i++)
        color +=  mix(color, uColors[i], 0.75) * exp(-50.0*abs(dists[i]));

    if(uEffects[ShowEdgeFill] > 0.)
    {
        if(uEffects[EdgeFillColor] >= 0.)
            edgeColor = mix(color, vec4(1, 1, 1, 0),uEffects[EdgeFillColor]);
        else
            edgeColor = mix(color, vec4(0, 0, 0, 0), abs(uEffects[EdgeFillColor]));
    }

    if(uEffects[ShowRadiusFill] > 0.)
    {
        if(uEffects[RadiusFillColor] >= 0.)
            radiusColor = mix(color, vec4(1, 1, 1, 0),uEffects[RadiusFillColor]);
        else
            radiusColor = mix(color, vec4(0, 0, 0, 0), abs(uEffects[RadiusFillColor]));
    }

    float edge = 1.7 * exp(uEffects[EdgeFillWidth] * -1. * abs(closest));
    float radius = 1.7 * (1. - exp(uEffects[RadiusFillWidth] * -1. * abs(closest)));

    float ew = edgeColor.w;
    float rw = radiusColor.w;
    vec4 ne = normalize(edgeColor) * edge;
    vec4 nr = normalize(radiusColor) * radius;

    ne.w = ew;
    nr.w = rw;

    return ne + nr;
}

vec4 outlineColor(vec4 color, float closest)
{
    vec4 oColor = vec4(0,0,0,1);
    float comps = 0.;

    for(int i = 0; i < 9; i++)
    {
        if(abs(dists[i]) >= uEffects[OutlineWidth])
            continue;
        oColor += uColors[i];
        comps += 1.;
    }

	if(comps > 1.)
	    oColor *= 1./comps;
    oColor.w = 1.0;

    if(uEffects[OutlineWeight] >= 0.)
        color = mix(color, mix( oColor, vec4(1.0),uEffects[OutlineWeight]), 1.0-smoothstep(0.0,uEffects[OutlineWidth],abs(closest)) );
    else
        color = mix(color, mix( oColor, vec4(0, 0, 0, 1), abs(uEffects[OutlineWeight])), 1.0-smoothstep(0.0,uEffects[OutlineWidth],abs(closest)) );


    return color;
}


void main(){
    textureSize = vec2(uResolution.zw);
    vec2 p = vUv;
    vec4 color = vec4(0.,0.,0.,0.);

    float closest = getClosest(p);
    if(closest < 0.)
        color = innerColor(closest);

    if(abs(closest) < uEffects[OutlineWidth])
        color = uEffects[ShowOutline] > 0. ? outlineColor(color, closest) : color;
    
    gl_FragColor=color;
}
`;

