export const calendarSdMap_CS = `
precision highp float;

#define PACK_RANGE 1.0

#define TWO_PI 6.28318530718
#define FLOAT_MAX  1.70141184e38
#define FLOAT_MIN  1.17549435e-38

#define SPOKE_LEN 0
#define RADIUS 1
#define B_CONTROL 2

uniform vec2 uResolution;
uniform float uStarDims[3];

vec2 iResolution;
float zRotation=0.;

// float radius=.2;
// float scale=.95;
//float radius=.1;
//float scale=.475;
vec4 background=vec4(0.,0.,0.,1.);

vec4 angles[29];

void setAngles()
{
    angles[0]=vec4(0,.8,0,0);
    angles[1]=vec4(.111090146,.08888889,0,0);
    angles[2]=vec4(.12493663,.1,0,0);
    angles[3]=vec4(.14292113,.114285715,0,0);
    angles[4]=vec4(.16663523,.13333334,0,0);
    angles[5]=vec4(.20005776,.16,0,0);
    angles[6]=vec4(.22218029,.08888889,0,0);
    angles[7]=vec4(.2500324,.2,0,0);
    angles[8]=vec4(.2856831,.114285715,0,0);
    angles[9]=vec4(.33327046,.26666668,0,0);
    angles[10]=vec4(.37496904,.1,0,0);
    angles[11]=vec4(.39995638,.16,0,0);
    angles[12]=vec4(.42860427,.114285715,0,0);
    angles[13]=vec4(.44451973,.08888889,0,0);
    angles[14]=vec4(.5000648,.4,0,0);
    angles[15]=vec4(.5556099,.08888889,0,0);
    angles[16]=vec4(.5713662,.114285715,0,0);
    angles[17]=vec4(.6000141,.16,0,0);
    angles[18]=vec4(.62500143,.1,0,0);
    angles[19]=vec4(.66670007,.26666668,0,0);
    angles[20]=vec4(.71428734,.114285715,0,0);
    angles[21]=vec4(.7499381,.2,0,0);
    angles[22]=vec4(.7777902,.08888889,0,0);
    angles[23]=vec4(.8000719,.16,0,0);
    angles[24]=vec4(.8333353,.13333334,0,0);
    angles[25]=vec4(.85720855,.114285715,0,0);
    angles[26]=vec4(.87503386,.1,0,0);
    angles[27]=vec4(.8888803,.08888889,0,0);
    angles[28]=vec4(1.,.8,0,0);
}

float map(float value, float min1, float max1, float min2, float max2) {
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

 float unpack(vec4 packedVal)
 {
    float scaled =  dot(packedVal, vec4(1.0, 1.0 / 255.0, 1.0 / 65025.0, 1.0 / 16581375.0));

     return map(scaled, 0., 1., -PACK_RANGE, PACK_RANGE);
 }

vec4 pack(float unpackedVal)
{
    float scaled = map(unpackedVal, -PACK_RANGE, PACK_RANGE, 0., 1.);
    
    vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * scaled;
    enc = fract(enc);
    enc -= enc.yzww * vec4(1.0 / 255.0, 1.0 / 255.0, 1.0 / 255.0, 0.0);
    return enc;
}

void getSegment(vec2 p,float rot,out float a1,out float l1,out float a2,out float l2)
{
    float angle=atan(p.y,p.x)/TWO_PI+rot/TWO_PI;
    if(angle<0.)angle+=1.;
    if(angle>1.)angle-=1.;
    for(int i=0;i<28;i++)
    {
        float ta=angles[i].x;
        float na=angles[i+1].x;
        if(angle>=ta&&angle<na)
        {
            a1=ta;
            a2=na;
            l1=angles[i].y;
            l2=angles[i+1].y;
            return;
        }
    }
    a1=0.;
    a2=0.;
    l1=0.;
    l1=0.;
}

vec2 getPoint(float angle,float len)
{
    float ang=(angle)*TWO_PI+zRotation;
    vec2 p=vec2(0);
    p.x=cos(ang)*len;
    p.y=sin(ang)*len;
    return p;
}

void getPoints(float a1,float l1,float a2,float l2,out vec2[3]hPoints)
{
    float len=(l1*uStarDims[SPOKE_LEN] + uStarDims[RADIUS]);
    hPoints[0]=getPoint(a1,len);
    
    float ang=(a2-a1)/2.+a1;
    hPoints[1]=getPoint(ang, uStarDims[B_CONTROL]);
    
    len=(l2*uStarDims[SPOKE_LEN] + uStarDims[RADIUS]);
    hPoints[2]=getPoint(a2,len);
}

// Test if point p crosses line (a, b), returns sign of result
float testCross(vec2 a,vec2 b,vec2 p)
{
    return sign((b.y-a.y)*(p.x-a.x)-(b.x-a.x)*(p.y-a.y));
}

//Determine which side we're on (using barycentric parameterization)
float signBezier(vec2 A,vec2 B,vec2 C,vec2 p)
{
    vec2 a=C-A,b=B-A,c=p-A;
    vec2 bary=vec2(c.x*b.y-b.x*c.y,a.x*c.y-c.x*a.y)/(a.x*b.y-b.x*a.y);
    vec2 d=vec2(bary.y*.5,0.)+1.-bary.x-bary.y;
    return mix(sign(d.x*d.x-d.y),mix(-1.,1.,
        step(testCross(A,B,p)*testCross(B,C,p),0.)),
        step((d.x-d.y),0.))*testCross(A,C,B);
}

// Solve cubic equation for roots
vec3 solveCubic(float a,float b,float c)
{
    float p=b-a*a/3.,p3=p*p*p;
    float q=a*(2.*a*a-9.*b)/27.+c;
    float d=q*q+4.*p3/27.;
    float offset=-a/3.;
    if(d>=0.){
        float z=sqrt(d);
        vec2 x=(vec2(z,-z)-q)/2.;
        vec2 uv=sign(x)*pow(abs(x),vec2(1./3.));
        return vec3(offset+uv.x+uv.y);
    }
    float v=acos(-sqrt(-27./p3)*q/2.)/3.;
    float m=cos(v);
    float n=sin(v)*1.732050808;
    return vec3(m+m,-n-m,n-m)*sqrt(-p/3.)+offset;
}

// Find the signed distance from a point to a bezier curve
float sdBezier(vec2 A,vec2 B,vec2 C,vec2 p)
{
    B=mix(B+vec2(1e-6),B,abs(sign(B*2.-A-C)));
    vec2 a=B-A,b=A-B*2.+C,c=a*2.,d=A-p;
    vec3 k=vec3(3.*dot(a,b),2.*dot(a,a)+dot(d,b),dot(d,a))/dot(b,b);
    vec3 t=clamp(solveCubic(k.x,k.y,k.z),0.,1.);
    vec2 pos=A+(c+b*t.x)*t.x;
    float dis=length(pos-p);
    pos=A+(c+b*t.y)*t.y;
    dis=min(dis,length(pos-p));
    return dis;
}

float closestToAny(vec2 p)
{
    float dist=10000.;
    vec2 cPoints[3];
    
    for(int s=0;s<28;s++)
    {
        float a1=angles[s].x;
        float l1=angles[s].y;
        float a2=angles[s+1].x;
        float l2=angles[s+1].y;
        getPoints(a1,l1,a2,l2,cPoints);
        float d=sdBezier(cPoints[0],cPoints[1],cPoints[2],p);
        if(d<dist)
        {
            dist=d;
        }
        
    }
    return dist;
}
    
void main()
{
    iResolution=uResolution;
    setAngles();
    vec2 uv=gl_FragCoord.xy/iResolution.xy;
    vec2 p=(2.*gl_FragCoord.xy-iResolution.xy)/iResolution.y;
    
    vec2 st=((p+vec2(1,1))/vec2(2,2));
    uv=st.xy;
    
    float d=closestToAny(p);
    float a1;
    float l1;
    float a2;
    float l2;
    getSegment(p,-zRotation,a1,l1,a2,l2);
    
    vec2 cPoints[3];
    getPoints(a1,l1,a2,l2,cPoints);
    
    d*=signBezier(cPoints[0],cPoints[1],cPoints[2],p);
    gl_FragColor=pack(d);
    // d = map(d, -PACK_RANGE, PACK_RANGE, 0., 1.);
    // gl_FragColor=vec4(d, d, d, 1.);
}
`