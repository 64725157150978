import * as Three from "three";
import * as Resize from "../Classes/ResizeHandler"
import { ObjectRef } from "../Interop/ObjectRef.js";
import { RenderLoop } from "../Classes/RenderLoop";
import { Player } from "../Classes/RenderLoop";
import { AutoRotate } from "../Actions/AutoRotate"
import { CalendarGL } from "../ThreePages/CalendarGL"
import { IndexGL } from "../ThreePages/IndexGL"
import { CompassGL } from "../ThreePages/CompassGL"
import Ephemeris from "../Ephemeris/Moshier/MoshierEphemeris"
import RendererResize = Resize.RendererResize;
import CameraResize = Resize.CameraResize;

import { BrowserTest } from "../ThreePages/BrowserTest"

export class ThreeInterop {
    private readonly canvases = new Map<string, any>();

    private objects = {
        Document: document,
        Window: window,
        ObjRef: ObjectRef,
        RenderLoop: RenderLoop,
        Player: Player,

        WebGLRenderer: Three.WebGLRenderer,
        WebGLRenderTarget: Three.WebGLRenderTarget,
        Scene: Three.Scene,
        Loader: Three.ObjectLoader,
        PerspectiveCamera: Three.PerspectiveCamera,
        MeshNormalMaterial: Three.MeshNormalMaterial,
        ShaderMaterial: Three.ShaderMaterial,
        BoxGeometry: Three.BoxGeometry,
        PlaneGeometry: Three.PlaneGeometry,
        Mesh: Three.Mesh,
        Vector2: Three.Vector2,

        AutoRotate: AutoRotate,
        ResizeHandler: Resize.ResizeHandler,
        DefaultRendererResize: RendererResize,
        DefaultCameraResize: CameraResize,

        CalendarGL: CalendarGL,
        IndexGL: IndexGL,
        CompassGL: CompassGL,
        

        Ephemeris: Ephemeris,

        BrowserTest: BrowserTest
    }


    public addCanvas(canvas: HTMLCanvasElement) {
        if (!canvas) throw new Error("Invalid canvas.");
        if (this.canvases.get(canvas.id)) return;

        this.canvases.set(canvas.id, canvas);
        return canvas.id;
    }

    public removeCanvas(canvasId: string) {
        this.canvases.delete(canvasId);
    }

    public resizeCanvas(canvasId: string, width: number, height: number) {
        const canvas = this.getCanvas(canvasId);
        canvas.style.width = width + "px";
        canvas.width = width;
        canvas.style.height = height + "px";
        canvas.height = height;
    }

    // Create / Delete Objects

    public getObjectRef(name: string) {
        return ObjectRef.storeObjRef(this.objects[name]);
    }

    public createObjectRef(name: string, parameters: Array<Object>) {
        var newObject;
        if(!parameters)
            newObject = new this.objects[name]();
        else
            newObject = new this.objects[name](...parameters);
        return ObjectRef.storeObjRef(newObject);
    }

    public createObjectRefAnon(name: string, parameters: Object) {
        var newObject;
        if (!parameters)
            newObject = new this.objects[name]();
        else
            newObject = new this.objects[name](parameters);
        return ObjectRef.storeObjRef(newObject);
    }

    public deleteObjectRef(id: number) {
        if (typeof ObjectRef.objRefs[id].dispose === "function")
            ObjectRef.objRefs[id].dispose();
        ObjectRef.removeObjectRef(id);
    }

    public deleteMultiObjectRef(ids: number[]) {
        ids.forEach(id => {
            if (typeof ObjectRef.objRefs[id].dispose === "function")
                ObjectRef.objRefs[id].dispose();
            ObjectRef.removeObjectRef(id);
        });
    }

    // Properties

    public getDomProperty(object: string, property: string) {
        var prop = this.objects[object][property];
        return prop;
    }

    public getProperty(object: Object, property: string) {
        var value = object[property];
        return value;
    }

    public setProperty(object: Object, property: string, value: Object) {
        object[property] = value;
    }


    public runObjectMethod(object: Object, method: string, args: Array<Object>) {
        if (!args)
            return object[method].apply(object, null);
        else
            return object[method].apply(object, args);
    }

    public runObjectMethodAnon(object: Object, method: string, args: Object) {
        var result =  object[method].call(object, args);
        return result;
    }

    //Runs a method on an object that creates another object and returns a reference to it
    public runObjectMethodRefAnon(object: Object, method: string, args: Object) {
        var newObject = object[method].call(object, args);
        return ObjectRef.storeObjRef(newObject);
    }

    public runObjectPropertyMethod(object: Object, property: string,method: string, args: Array<Object>) {
        object[property][method].apply(object[property], args);
    }



    private getCanvas = (canvasId: string) => {
        if (!canvasId) throw new Error("Invalid canvas Id.");

        const canvas = this.canvases.get(canvasId);
        if (!canvas) throw new Error("Canvas not found.");

        return canvas;
    }

}