export const calendar_FS = `
precision highp float;

#define PACK_RANGE 1.0

#define TWO_PI 6.28318530718
#define HALF_PI 1.57079632679

#define Index 0
#define Distance 1
#define Harmonic 2


#define ShowOutline 0
#define OutlineWeight 1
#define OutlineWidth 2
#define ShowEdgeFill 3
#define EdgeFillColor 4
#define EdgeFillWidth 5
#define ShowRadiusFill 6
#define RadiusFillColor 7
#define RadiusFillWidth 8
#define RippleAdvance 9
#define ShowOuterRing 10
#define ShowInnerRing 11

#define Outer 1
#define Reflection 2
#define Pupil 3
#define NotchWidth 4

precision highp float;


uniform sampler2D uBuffer;
uniform vec4 uResolution;
uniform float uSolar[9];
uniform float uIris[5];
uniform vec4 uColors[10];
uniform float uEffects[12];

float dists[9];
vec2 textureSize;

float map(float value, float min1, float max1, float min2, float max2) 
{
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

 float unpack(vec4 packedVal)
 {
    float scaled =  dot(packedVal, vec4(1.0, 1.0 / 255.0, 1.0 / 65025.0, 1.0 / 16581375.0));

     return map(scaled, 0., 1., -PACK_RANGE, PACK_RANGE);
 }

vec4 pack(float unpackedVal)
{
    float scaled = map(unpackedVal, -PACK_RANGE, PACK_RANGE, 0., 1.);
    
    vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * scaled;
    enc = fract(enc);
    enc -= enc.yzww * vec4(1.0 / 255.0, 1.0 / 255.0, 1.0 / 255.0, 0.0);
    return enc;
}

float texture2DLinear( sampler2D texSampler, vec2 uv) {
    vec2 pixelOff = vec2(0.5,0.5)/textureSize;

    float tl = unpack(texture2D(texSampler, uv + vec2(-pixelOff.x,-pixelOff.y)));
    float tr = unpack(texture2D(texSampler, uv + vec2(pixelOff.x,-pixelOff.y)));
    float bl = unpack(texture2D(texSampler, uv + vec2(-pixelOff.x,pixelOff.y)));
    float br = unpack(texture2D(texSampler, uv + vec2(pixelOff.x,pixelOff.y)));

    vec2 f = fract( (uv.xy-pixelOff) * textureSize );
    float tA = mix( tl, tr, f.x );
    float tB = mix( bl, br, f.x );
    return mix( tA, tB, f.y );
}


vec2 rotate(vec2 v, float a) {
	float s = sin(a);
	float c = cos(a);
	mat2 m = mat2(c, -s, s, c);
	return m * v;
}

float getBodies(vec2 p)
{
    float closest = 10000.;
    for(int i = 0; i < 9; i++)
    {
        float sd = texture2DLinear(uBuffer,rotate(p, uSolar[i]) * .25 + vec2(.5)) * 2.;
        dists[i] = sd;
        if(sd < closest)
            closest = sd;
    }
    return closest;
}

vec4 innerColor(float closest)
{
    vec4 edgeColor = vec4(0,0,0,1);
    vec4 radiusColor = vec4(0,0,0,1);
    vec4 color = vec4(0,0,0,1);

    for (int i = 0; i < 9; i++)
        color +=  mix(color, uColors[i], 0.75) * exp(-50.0*abs(dists[i]));

    if(uEffects[ShowEdgeFill] > 0.)
    {
        if(uEffects[EdgeFillColor] >= 0.)
            edgeColor = mix(color, vec4(1, 1, 1, 0),uEffects[EdgeFillColor]);
        else
            edgeColor = mix(color, vec4(0, 0, 0, 0), abs(uEffects[EdgeFillColor]));
    }

    if(uEffects[ShowRadiusFill] > 0.)
    {
        if(uEffects[RadiusFillColor] >= 0.)
            radiusColor = mix(color, vec4(1, 1, 1, 0),uEffects[RadiusFillColor]);
        else
            radiusColor = mix(color, vec4(0, 0, 0, 0), abs(uEffects[RadiusFillColor]));
    }

    float edge = 2. * exp(uEffects[EdgeFillWidth] * -1. * abs(closest));
    float radius = 2. * (1. - exp(uEffects[RadiusFillWidth] * -1. * abs(closest)));

    float ew = edgeColor.w;
    float rw = radiusColor.w;
    vec4 ne = normalize(edgeColor) * edge;
    vec4 nr = normalize(radiusColor) * radius;

    ne.w = ew;
    nr.w = rw;

    return ne + nr;
}

vec4 outerColor(float closest)
{
    return vec4(0.5, 0.5, 1.0, 1.0) * (1.0 - exp(-4.0*abs(closest))) * (0.8 + 0.2*cos(140.*closest)) * 0.5;
}

vec4 outlineColor(vec4 color, float closest)
{
    vec4 oColor = vec4(0,0,0,1);
    float comps = 0.;

    for(int i = 0; i < 9; i++)
    {
        if(abs(dists[i]) >= uEffects[OutlineWidth])
            continue;
        oColor += uColors[i];
        comps += 1.;
    }

	if(comps > 1.)
	    oColor *= 1./comps;
    oColor.w = 1.0;

    if(uEffects[OutlineWeight] >= 0.)
        color = mix(color, mix( oColor, vec4(1.0),uEffects[OutlineWeight]), 1.0-smoothstep(0.0,uEffects[OutlineWidth],abs(closest)) );
    else
        color = mix(color, mix( oColor, vec4(0, 0, 0, 1), abs(uEffects[OutlineWeight])), 1.0-smoothstep(0.0,uEffects[OutlineWidth],abs(closest)) );


    return color;
}

vec4 drawRing(vec2 p, vec4 color, float radius, float pixAngle, bool notches)
{
    if(abs(length(p) - radius) > 0.02)
        return color;

    float rad, thk;
    if(uResolution.x < uResolution.y){
        rad = radius * uResolution.x / uResolution.y;
        thk = 0.02 * uResolution.x / uResolution.y;
    }
    else{
        rad = radius;
        thk = 0.02;
    }

    float l = length(p); 
    vec4 gold =  mix(vec4(0., 0., 0., 1.), uColors[9], 1. - smoothstep(0., thk, abs(rad - l)));

    if(notches && uIris[NotchWidth] > 0. && gold != vec4(0., 0., 0., 1.))
    {
        float notchStep = TWO_PI / 12.;
        float angle = 0.;
        float tAngle = pixAngle + HALF_PI;
        float closest = 10000.;
        int notch = 0;
        for(int n = 0; n < 12; n++)
        {
            float diff = abs(angle - tAngle);
            diff = min(diff, abs(TWO_PI - diff));
            if(diff < closest)
            {
                closest = diff;
                notch = n;
            }
            angle += notchStep;
        }

        float width = notch == 0 ? uIris[NotchWidth] * 2. : uIris[NotchWidth];

        if(closest <= width)
        {
            vec4 darkGold = notch == 0 ? vec4((gold.xyz) / 1.4, 1) : vec4((gold.xyz) / 1.25, 1);
            gold =  mix(darkGold, gold, smoothstep(0., width, closest));
        }

    }

    return mix(color, gold, 1. - smoothstep(0., thk , abs(rad - l)));
}

vec2 getPoint(float angle, float len)
{
    vec2 p = vec2(0);
    p.x = cos(angle) * len;
    p.y = sin(angle) * len;
    return p;
}


void main(){
    textureSize = vec2(uResolution.zw);
    vec2 p = (2.0*gl_FragCoord.xy - uResolution.xy) / uResolution.y;
    float pixAngle = atan(p.y,p.x) * -1.;

    vec4 color=vec4(0,0,0,0);

    float closest;

    closest = getBodies(p);
    if(closest < 0.)
    {
        if(length(p) > uIris[Reflection])
            color = innerColor(closest);
    }
    else
        color = outerColor(closest);
    
    if(abs(closest) < uEffects[OutlineWidth])
        color = uEffects[ShowOutline] > 0. ? outlineColor(color, closest) : color;

    if(uEffects[ShowOuterRing] > 0.)
        color = drawRing(p, color, uIris[Reflection], pixAngle, true);
    gl_FragColor=vec4(color.xyz, 1.);
}`
