import * as Three from "three"
import { AngleLPF } from "./AngleLPF";

const pi2 = Math.PI * 2;

export interface ISpatialHandler {
    onLocationChanged(coOrds: GeoLocationCoordinates) : void;
    onOrientationChanged(quaternion: Three.Quaternion, direction: number) : void;
}

export class Spatial {

    private _owner: ISpatialHandler;
    private _dotNetRef: any;
    private _screenAngle = 0;
    private _geoCoords: GeoLocationCoordinates;
    private _magOffset: MagneticOffset =  { inclination: 0, declination: 0 };;
    private _locationRefreshRate: number = 60000;
    private _lastAlpha: number = 0;
    private _sensor: AbsoluteOrientationSensor;
    private _orientation: string;

    private xLpf = new AngleLPF();
    private zLpf = new AngleLPF();

    private _deviceOrientationHandler: EventListener;
    private _screenOrientationHandler: EventListener;
    private _windowOrientationHandler: EventListener;
    private _locationUpdateHandler: PositionCallback;
    private _locationErrorHandler: PositionErrorCallback;
    private _locationTimer: number;

    private _isDisposed = false;

    constructor(owner: ISpatialHandler, dotNetRef: any) {
        this._owner = owner;
        this._dotNetRef = dotNetRef;

        if ("orientation" in screen) {
            this._screenOrientationHandler = this.onScreenOrientation.bind(this);
            screen.orientation.addEventListener("change", this._screenOrientationHandler);
            this.onScreenOrientation();
        } else {
            //Safari, Opera, Android
            this._windowOrientationHandler = this.onWindowOrientation.bind(this);
            window.addEventListener("orientationchange", this._windowOrientationHandler);
            this.onWindowOrientation();
        }
    }

    //---------------------------------------------------------------------------------------------------
    //Methods

    public async init() {
        var prompt = window.localStorage.getItem("SpatialPrompt");
        if (prompt !== "Continue") {
            prompt = await this.dialog("SpatialPrompt");
            window.localStorage.setItem("SpatialPrompt", prompt);
            if (prompt !== "Continue") {
                this.error("Access", "Declined");
                return;
            }
        }

        if (!await this.queryGeoLocation())
            return;
        else
            this.getGeoLocation();

        if (!await this.initOrientation())
            return;

        window.addEventListener(
            "compassneedscalibration",
            () => {
                this.dialog("CompassNeedsCalibration");
            },
            false
        );
    }

    private async queryGeoLocation() {
        if (!(window.navigator && window.navigator.geolocation)) {
            this.error("Location", "Unavailable");
            return false;
        }

        var result = await navigator.permissions.query({ name: "geolocation" as PermissionName });
        if (result.state !== "granted" && result.state !== "prompt") {
            this.error("Location", "Denied");
            return false;
        }
        return true;
    }

    private getGeoLocation() {
        if (this._isDisposed)
            return;
        var positionOptions = {
            enableHighAccuracy: true,
            timeout: 10 * 1000,
            maximumAge: 30 * 1000,
        };
        if (!(this._locationUpdateHandler && this._locationErrorHandler)) {
            this._locationUpdateHandler = this.onLocationUpdate.bind(this);
            this._locationErrorHandler = this.onLocationError.bind(this);
        }
        navigator.geolocation.getCurrentPosition(this._locationUpdateHandler, this._locationErrorHandler, positionOptions);
        this._locationTimer = window.setTimeout(() => {
            if (this._geoCoords && this._geoCoords.speed) {
                if (this._geoCoords.speed > 3333) // > 200Kph update every second
                    this._locationRefreshRate = 1000;
                else if (this._geoCoords.speed > 1000) // > 60Kph update ever 30 seconds
                    this._locationRefreshRate = 30000;
                else if (this._geoCoords.speed > 100) // > 6Kph update every 2 minute
                    this._locationRefreshRate = 120000;
                else
                    this._locationRefreshRate = 300000; // else every 5 minutes
            }
            this.getGeoLocation();
        }, this._locationRefreshRate);
    }

    private async initOrientation() {
        if ("AbsoluteOrientationSensor" in window) {
            const accelerometer = "accelerometer" as PermissionName;
            const magnetometer = "magnetometer" as PermissionName;
            const gyroscope = "gyroscope" as PermissionName;

            var results = await Promise.all([
                navigator.permissions.query({ name: accelerometer }),
                navigator.permissions.query({ name: magnetometer }),
                navigator.permissions.query({ name: gyroscope }),
            ]);

            if (results.every((result) => result.state === "granted")) {
                this._sensor = new AbsoluteOrientationSensor({ frequency: 60, referenceFrame: "device" });
                this._sensor.addEventListener("error", event => {
                    // Handle runtime errors.
                    if (event.error.name === "NotAllowedError") {
                        this.error("Orientation Sensor", "Permission Denied");
                    } else if (event.error.name === "NotReadableError") {
                        this.error("Orientation Sensor", "Cannot Connect");
                    }
                });
                this._sensor.onreading = () => {
                    const quaternion = new Three.Quaternion();
                    quaternion.fromArray(this._sensor.quaternion);
                    this.updateFromQuaternion(quaternion);
                };
                this._sensor.onerror = console.error;
                this._sensor.start();
                this._orientation = "sensor";
                return true;
            } else {
                this.error("Orientation Sensor", "Permission Denied");
                return false;
            }
        } else if ("ondeviceorientationabsolute" in window) {
            this._deviceOrientationHandler = this.onDeviceOrientationChanged.bind(this);
            window.addEventListener("deviceorientationabsolute", this._deviceOrientationHandler, false);
            this._orientation = "deviceAbsolute";
        //    return { error: false, msg: "Using OnDeviceOrientationAbsolute" };
            return true;
        } else if ("ondeviceorientation" in window) {
            this._deviceOrientationHandler = this.onDeviceOrientationChanged.bind(this);
            window.addEventListener("deviceorientation", this._deviceOrientationHandler, false);
            this._orientation = "device";
            //return { error: false, msg: "Using OnDeviceOrientation" };
            return true;
        } else {
            this.error("Orientation Sensor", "Unavailable");
            return false;
        }
    }


    private updateFromQuaternion(quaternion: Three.Quaternion) {

        // euler will hold the Euler angles corresponding to the quaternion
        let euler = new Three.Euler(0, 0, 0);

        // Order of rotations must be adapted depending on orientation
        // for portrait ZYX, for landscape ZXY
        let angleOrder = null;
        screen.orientation.angle === 0 ? angleOrder = 'ZYX' : angleOrder = 'ZXY';
        euler.setFromQuaternion(quaternion, angleOrder);

        const alpha = euler.z * 180 / Math.PI;
        const beta = euler.x * 180 / Math.PI;
        const gamma = euler.y * 180 / Math.PI;

        //this._owner.onAbsoluteChanged(alpha, beta, gamma);
        this.update(alpha, beta, gamma);
    };

    private update(alpha: number, beta: number, gamma: number) {
        let angleOrder = null;
        screen.orientation.angle === 0 ? angleOrder = 'ZYX' : angleOrder = 'ZXY';

        let x = 0, y = 0, z = 0;
        let a = alpha * Math.PI / 180 + this._magOffset.declination * Math.PI / 180;
        let b = beta * Math.PI / 180;
        let g = gamma * Math.PI / 180;

        switch (this._screenAngle) {
        default:
        case 0:
            z = a;
            x = b;
            break;
        case 90:
            z = a - Math.PI / 2;
            x = g;
            break;
        case 270:
            z = a + Math.PI / 2;
            x = -g;
            break;
        }

        const euler = new Three.Euler(this.xLpf.smooth(x), y, this.zLpf.smooth(z), angleOrder);
        const q = new Three.Quaternion().setFromEuler(euler);

        const deg = a * 180 / Math.PI;
        this._owner.onOrientationChanged(q, (-deg + 360) % 360);
    }


    private dialog(message: string) {
        return this._dotNetRef.invokeMethodAsync("ShowMessageBox", message);
    }

    private error(source: string, msg: string) {
        this._dotNetRef.invokeMethodAsync("JSError", source, msg);
    }

    //---------------------------------------------------------------------------------------------------
    //Dispose

    public dispose() {
        window.clearTimeout(this._locationTimer);

        if (this._orientation === "sensor" && this._sensor) {
            this._sensor.stop();
        } else if (this._orientation === "deviceAbsolute") {
            window.removeEventListener("deviceorientationabsolute", this._deviceOrientationHandler, false);
        } else if (this._orientation === "device") {
            window.removeEventListener("deviceorientation", this._deviceOrientationHandler, false);
        }

        this._isDisposed = true;
    }

    //---------------------------------------------------------------------------------------------------
    //Event Listener

    public onScreenOrientation() {
        const angle = screen.orientation.angle;
        this._screenAngle = angle < 0 ? angle + 360 : angle;
    }

    public onWindowOrientation() {
        const angle = window.orientation;
        this._screenAngle = angle < 0 ? angle + 360 : angle;
    }

    public async onLocationUpdate(position: GeolocationPosition) {
        this._geoCoords = position.coords;

        const offset = await this._dotNetRef.invokeMethodAsync(
            "GetMagOffset",
            position.coords.longitude,
            position.coords.latitude,
            position.coords.altitude || 0.0
        );
        this._magOffset = { inclination: offset[0], declination: offset[1] };
        this._owner.onLocationChanged(position.coords);
    }

    public onLocationError(error: GeolocationPositionError) {
        this._geoCoords = null;
        switch(error.code) {
            case 1:
                this.error("Location", "Denied");
                break;
            case 2:
                this.error("Location", "Unavailable");
                break;
            case 3:
                this.error("Location", "Timed Out");
                break;
        }
    }

    private onDeviceOrientationChanged(event) {
    //    let absolute = event.absolute;
    //    let alpha = event.alpha ? event.alpha : 0; // Z
    //    let beta = event.beta ? event.beta : 0; // Z
    //    let gamma = event.gamma ? event.gamma : 0; // Z

    //    // Safari
    //    if (absolute === false && event.webkitCompassHeading && event.webkitCompassAccuracy) {
    //        if (event.webkitCompassAccuracy > 0 && event.webkitCompassAccuracy < 50) {
    //            if (event.webkitCompassHeading > 0) {
    //                alpha = event.webkitCompassHeading;
    //                absolute = true;
    //            }
    //        }
    //    }

    //    this._owner.onRelativeChanged(alpha, beta, gamma, absolute);
    //    this.update(alpha, beta, gamma);
    }


}
