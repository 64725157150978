import * as Three from "three";
import { GalaxyBase } from "../ThreePages/GalaxyBase";
import Shaders from "../Shaders/Shaders"
import * as Stella from "../Classes/Stella"
import StarBuilder from "../Classes/StarBuilder"


const bufferUniforms = {
    uResolution: { type: "v2", value: new Three.Vector2() },
    uStarDims: { type: "fv1", value: Stella.starDims }
};

const homeScreenUniforms = {
    uBuffer: { value: undefined },
    uResolution: { value: new Three.Vector4() },
    uSolar: { type: "fv4", value: Stella.solar },
    uColors: { type: "fv4", value: Stella.colors },
    uEffects: { type: "fv1", value: Stella.effects },
};


export class IndexGL extends GalaxyBase {
    protected _sdfBuffer: Three.WebGLRenderTarget;
    protected _sdfScene: Three.Scene;

    constructor(canvas: HTMLCanvasElement, timeElement: string) {
        super(canvas, timeElement);

        this.updateAstro(new Date());
        this.init();
        this.createScene();
        this.updateBodies();

        this._controls.enablePan = false;
        this._controls.enableDamping = true;
        this._controls.dampingFactor = 0.05;

        this.createRenderLoop(); // must be done after scene is created
    }

    private init() {
        this.createSdfBuffer();
    }

    protected initialCameraPosition() {
        const up = new Three.Vector3(0, 1, 0);
        const axis = new Three.Vector3(1, 0, 0);
        up.applyAxisAngle(axis, 0.40910518);

        var ratio = 2 * Math.tan((this._camera.fov * Math.PI / 180) / 2);
        if (this._camera.aspectRatio > 1.0)
            ratio *= this._camera.aspectRatio;

        var initialDist = (Stella.starSize / ratio) / .95;

        this._camera.position.set(up.x * initialDist, up.y * initialDist, up.z * initialDist);
        this._camera.up = up;
    }

    private createSdfBuffer() {
        const desiredWidth = this._canvas.clientWidth;
        const desiredHeight = this._canvas.clientHeight;
        const size = desiredWidth < desiredHeight ? desiredWidth : desiredHeight;

        const buffer = new Three.WebGLRenderTarget(size, size,
            {
                minFilter: Three.NearestFilter,
                magFilter: Three.NearestFilter,
                format: Three.RGBAFormat,
                type: Three.UnsignedByteType,
                stencilBuffer: false,
                depthBuffer: false
            });
        buffer.texture.needsUpdate = false;
        this._sdfBuffer = buffer;
        bufferUniforms.uResolution.value.x = size;
        bufferUniforms.uResolution.value.y = size;
        const mesh = new Three.Mesh(
            new Three.PlaneGeometry(2, 2),
            new Three.ShaderMaterial({
                uniforms: bufferUniforms,
                vertexShader: Shaders.sdfBuffer_VS,
                fragmentShader: Shaders.sdfBuffer_FS,
                depthWrite: false,
                depthTest: false,
            })
        );
        this._sdfScene = new Three.Scene();
        this._sdfScene.add(mesh);

        const camera = new Three.PerspectiveCamera(Stella.fov, 1, 1, 10);

        this._renderer.setRenderTarget(this._sdfBuffer);
        this._renderer.render(this._sdfScene, camera);
        this._renderer.setRenderTarget(null);

        this.meshDispose(mesh);
    }

    protected createScene() {
        super.createScene();

        if (this._isWebGL2)
            this.add3DHarmonics();
        else
            this.add2DHarmonics();

    }

    private add3DHarmonics() {
        var shape = StarBuilder.buildBezierStar(9, 0.99, 0.2, 0.15);
        const scale = Stella.starSize / 2;

        const resolution4 = new Three.Vector4();
        resolution4.x = this._renderer.domElement.width;
        resolution4.y = this._renderer.domElement.height;
        resolution4.z = this._sdfBuffer.width;
        resolution4.w = this._sdfBuffer.height;

        const thickness = .004;

        for (let body = 0; body < Stella.bodies; body++) {
            const materials = [
                new Three.ShaderMaterial({
                    uniforms: {
                        uBuffer: { value: this._sdfBuffer.texture },
                        uResolution: { value: resolution4 },
                        uSolar: { value: Stella.solar },
                        uColors: { value: Stella.colors },
                        uEffects: { value: Stella.effects },
                        uIndex: { value: body }
                    },
                    vertexShader: Shaders.harmonic3D_VS,
                    fragmentShader: Shaders.harmonic3D_FS,
                    depthWrite: false,
                    depthTest: true,
                    transparent: true,
                }),
                new Three.MeshBasicMaterial({
                    color: Stella.bodyColors[body],
                    side: Three.DoubleSide,
                    depthTest: true,
                })
            ];

            const geometry = new Three.ExtrudeGeometry(shape, { depth: thickness, bevelEnabled: false });

            const mesh = new Three.Mesh(
                geometry,
                materials
            );
            const euler = new Three.Euler(Math.PI / 2, Stella.solar[body].y, Stella.solar[body].x, "XZY");
            mesh.setRotationFromEuler(euler);
            mesh.scale.set(scale, scale, scale);
            mesh.translateZ(-thickness / 2);

            this._ecliptic.add(mesh);
        }
    }

    private add2DHarmonics() {
        homeScreenUniforms.uResolution.value.x = this._sdfBuffer.width;
        homeScreenUniforms.uResolution.value.y = this._sdfBuffer.height;
        homeScreenUniforms.uResolution.value.z = this._sdfBuffer.width;
        homeScreenUniforms.uResolution.value.w = this._sdfBuffer.height;
        homeScreenUniforms.uBuffer.value = this._sdfBuffer.texture;

        const geometry = new Three.PlaneGeometry(Stella.starSize, Stella.starSize);
        geometry.rotateX(Math.PI / 2);

        const mesh = new Three.Mesh(
            geometry,
            new Three.ShaderMaterial({
                uniforms: homeScreenUniforms,
                vertexShader: Shaders.harmonic2D_VS,
                fragmentShader: Shaders.harmonic2D_FS,
                depthWrite: false,
                depthTest: true,
                transparent: true,
                side: Three.DoubleSide,
            })
        );
        this._ecliptic.add(mesh);
    }

    public dispose() {
        super.dispose();
        this._sdfBuffer.dispose();
    }

    public onPostRender(frameNumber: number, timeDelta: number) {
        this._controls.update();
        super.onPostRender(frameNumber, timeDelta);
    }

}