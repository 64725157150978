import * as Bowser from "bowser";

export class BrowserInterop {
    private onResizeCallbacks: Array<{observer: ResizeObserver, callback: any}> = [];
    private throttled = false;
    private delay = 50;
    //Methods
    public windowSize() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    public boundingRect(element) {
        if (element == undefined)
            return undefined;
        return element.getBoundingClientRect();
    }

    public addOnResizeEvent(element: HTMLElement, callback: any) {
        const observer = new ResizeObserver(this.onResize.bind(this));
        observer.observe(element);
        this.onResizeCallbacks[element.id] = { observer, callback };
        return element.id;
    }

    public removeResizeEvent(id) {
        this.onResizeCallbacks[id].observer.disconnect();
        delete this.onResizeCallbacks[id];
    }
    //Event Handlers
    onResize(e) {
        if (!this.throttled) {
            this.throttled = true;
            this.doResize(e);
            setTimeout(() => {
                this.throttled = false;
                this.doResize(e);
            }, this.delay);
        }
    }

    public doResize(e) {
        const targetId = e[0].target.id;
        this.onResizeCallbacks[targetId].callback.invokeMethodAsync("OnResizeCallback", targetId);
    }

    public browserInfo() {
        const info = Bowser.parse(window.navigator.userAgent);
        return {
            BrowserName:  info.browser.name,
            BrowserVersion:  info.browser.version,
            OSName:  info.os.name,
            OSVersion:  info.os.version,
            OSVersionName:  info.os.versionName,
            PlatformType: info.platform.type,
            PlatformModel: info.platform.model,
            PlatformVendor: info.platform.vendor,
            EngineName:  info.engine.name,
            EngineVersion:  info.engine.version,
        };
    }

    public isStorage(key: string): boolean {
        return window.localStorage.getItem(key) !== null;
    }

    public getStorage(key: string): string {
        return window.localStorage.getItem(key);
    }

    public setStorage(key: string, value: string) {
        window.localStorage.setItem(key, value);
    }

    public clearStorage() {
        window.localStorage.clear();
    }

    public removeStorage(key: string) {
        window.localStorage.removeItem(key);
    }
}

