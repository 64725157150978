class dateTimeString
{
    private _local: string;

    constructor(){
        this._local = navigator.languages[0];
    }

    public get(date: Date): string {
        return date.toLocaleString(this._local,
            {
                weekday: "short",
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
            }
        );
    }
}

const DateTimeString = new dateTimeString();
export default DateTimeString;