import * as Three from "three";

export interface IResizeAction {
    onResize(width: number, height: number);
}

export class ResizeHandler {
    protected canvas: HTMLCanvasElement;
    protected resizeActions: Array<IResizeAction> = [];
    protected observer: ResizeObserver;
    protected throttled = false;
    protected delay = 50;
    protected callBack: ResizeObserverCallback;

    constructor(canvas: HTMLCanvasElement) {
        this.canvas = canvas;

        this.callBack = this.onResize.bind(this);
        this.observer = new ResizeObserver(this.callBack);
        this.observer.observe(this.canvas);
    }

    public addAction(action: IResizeAction) {
        this.resizeActions.push(action);
    }

    private onResize() {
        if (!this.throttled) {
            this.throttled = true;
            this.doResize();
            setTimeout(() => {
                this.throttled = false;
                this.doResize();
            }, this.delay);
        }
    }

    private doResize() {
        //this.canvas.width = this.canvas.clientWidth;// * window.devicePixelRatio;
        //this.canvas.height = this.canvas.clientHeight;// * window.devicePixelRatio;
        for (const action of this.resizeActions) {
            action.onResize(this.canvas.clientWidth, this.canvas.clientHeight);
        }
    }
};

export class RendererResize implements IResizeAction{
    private readonly renderer: Three.WebGLRenderer;

    constructor(renderer: Three.WebGLRenderer) {
        this.renderer = renderer;
    }

    public onResize(width: number, height: number) {
        this.renderer.setViewport(0, 0, width, height);
    }
}

export class CameraResize implements IResizeAction {
    private readonly camera: Three.Camera;

    constructor(camera: Three.Camera) {
        this.camera = camera;
    }

    public onResize(width: number, height: number) {
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
    }
}