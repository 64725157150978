import * as Three from "three";
import { RenderLoop, IPreRenderAction } from "../Classes/RenderLoop"
import { ResizeHandler, IResizeAction } from "../Classes/ResizeHandler"
import Ephemeris from "../Ephemeris/Moshier/MoshierEphemeris"
import Shaders from "../Shaders/Shaders"
import { Text } from "troika-three-text"

import * as Stella from "../Classes/Stella"
import colors = Stella.colors;
import iris = Stella.iris;
import effects = Stella.effects;


const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const starDims = [
    0.475,  //spoke length
    0.1,    //inner radius
    0.075   //bezier control
];

const solar = [
    -.6775986,
    -.09537878,
    -.534356,
    -.49974582,
    -.9761879,
    -.76881605,
    -.82625896,
    -5.641171,
    -5.39114,
];

export const bufferUniforms = {
    uResolution: { type: "v2", value: new Three.Vector2() },
    uStarDims: { type: "fv1", value: starDims }
};

export const screenUniforms = {
    uBuffer: { value: undefined },
    uResolution: { value: new Three.Vector4() },
    uSolar: { type: "fv1", value: solar },
    uIris: { type: "fv1", value: iris },
    uColors: { type: "fv4", value: colors },
    uEffects: { type: "fv1", value: effects },
};


export class CalendarGL implements IPreRenderAction, IResizeAction {
    private readonly _canvas: HTMLCanvasElement;
    private _renderer: Three.WebGLRenderer;
    private _buffer: Three.WebGLRenderTarget;
    private _bufferScene: Three.Scene;
    private _normalScene: Three.Scene;
    private _normalCamera: Three.PerspectiveCamera;
    private _renderLoop: RenderLoop;

    private _startJD = 0;
    private _endJD = 0;
    private _stepJD = 0;

    private dayText: Text;
    private monthText: Text;
    private yearText: Text;



    constructor(canvas: HTMLCanvasElement) {
        this._canvas = canvas;
        this.createRenderer();
        this.createBufferScene();
        this.renderBufferScene();
        this.createNormalScene();
        this.createText();
    }

    public setRenderLoop(renderLoop: RenderLoop, statsMode: number) {
        this._renderLoop = renderLoop;
        this._renderLoop.renderer = this._renderer;
        this._renderLoop.setStatsMode(statsMode);
        this._renderLoop.add(this._normalScene, this._normalCamera);
        this._renderLoop.addPreRender(this);
    }

    public setJDs(startJD: number, endJD: number, stepJD: number) {
        this._startJD = startJD;
        this._endJD = endJD;
        this._stepJD = stepJD;
    }


    private createRenderer() {
        const renderer = new Three.WebGLRenderer({ canvas: this._canvas, antialias: true });
        renderer.setSize(this._canvas.width, this._canvas.height);
        renderer.outputEncoding = Three.sRGBEncoding;
        renderer.autoClear = false;
        this._renderer = renderer;
    }

    private createBufferScene() {
        const buffer = new Three.WebGLRenderTarget(this._canvas.width * 2, this._canvas.height * 2,
            {
                minFilter: Three.NearestFilter,
                magFilter: Three.NearestFilter,
                format: Three.RGBAFormat,
                type: Three.UnsignedByteType,
                stencilBuffer: false,
                depthBuffer: false
            });
        buffer.texture.needsUpdate = false;
        this._buffer = buffer;
        bufferUniforms.uResolution.value.x = this._buffer.width;
        bufferUniforms.uResolution.value.y = this._buffer.height;
        const mesh = new Three.Mesh(
            new Three.PlaneGeometry(2, 2),
            new Three.ShaderMaterial({
                uniforms: bufferUniforms,
                vertexShader: Shaders.default_VS,
                fragmentShader: Shaders.calendarSdMap_CS,
                depthWrite: false,
                depthTest: false,
            })
        );
        this._bufferScene = new Three.Scene();
        this._bufferScene.add(mesh);
    }

    private renderBufferScene() {
        const camera = new Three.PerspectiveCamera(60, this._buffer.width / this._buffer.eight, 1, 10);
        this._renderer.setRenderTarget(this._buffer);
        this._renderer.render(this._bufferScene, camera);
        this._renderer.setRenderTarget(null);

    }

    private createText() {
        const dayText = new Text();
        const monthText = new Text();
        const yearText = new Text();
        this._normalScene.add(dayText);
        this._normalScene.add(monthText);
        this._normalScene.add(yearText);

        // Set properties to configure:
        dayText.fontSize = 0.1;
        dayText.position.z = -2;
        dayText.color = 0xC9C4B3;
        dayText.anchorX = "center";
        dayText.anchorY = -0.18;

        monthText.fontSize = 0.08;
        monthText.position.z = -2;
        monthText.color = 0xC9C4B3;
        monthText.anchorX = "center";
        monthText.anchorY = -.06;

        yearText.fontSize = 0.1;
        yearText.position.z = -2;
        yearText.color = 0xC9C4B3;
        yearText.anchorX = "center";
        yearText.anchorY = 0.05;

        // Update the rendering:
        dayText.sync();
        monthText.sync();
        yearText.sync();
        this.dayText = dayText;
        this.monthText = monthText;
        this.yearText = yearText;
    }

    private createNormalScene() {
        screenUniforms.uResolution.value.x = this._canvas.width;
        screenUniforms.uResolution.value.y = this._canvas.height;
        screenUniforms.uResolution.value.z = this._buffer.width;
        screenUniforms.uResolution.value.w = this._buffer.height;
        screenUniforms.uBuffer.value = this._buffer.texture;

        const mesh = new Three.Mesh(
            new Three.PlaneGeometry(2, 2),
            new Three.ShaderMaterial({
                uniforms: screenUniforms,
                vertexShader: Shaders.default_VS,
                fragmentShader: Shaders.calendar_FS,
                depthWrite: false,
                depthTest: false,
            })
        );

        this._normalScene = new Three.Scene();
        this._normalScene.add(mesh);
        this._normalCamera = new Three.PerspectiveCamera(60, this._canvas.width / this._canvas.height, 1, 10);
    }

    private toGregorian(julianDate: number) {
        var month, day; // int
        var year, a, c, d, x, y, jd; // int
        var BC; // int
        var dd; // double

        /* January 1.0, 1 A.D. */
        if (julianDate < 1721423.5) {
            BC = 1;
        } else {
            BC = 0;
        }

        jd = Math.floor(julianDate + 0.5); /* round Julian date up to integer */

        /* Find the number of Gregorian centuries
         * since March 1, 4801 B.C.
         */
        a = Math.floor((100 * jd + 3204500) / 3652425);

        /* Transform to Julian calendar by adding in Gregorian century years
         * that are not leap years.
         * Subtract 97 days to shift origin of JD to March 1.
         * Add 122 days for magic arithmetic algorithm.
         * Add four years to ensure the first leap year is detected.
         */
        c = jd + 1486;
        if (jd >= 2299160.5) {
            c += a - Math.floor(a / 4);
        } else {
            c += 38;
        }
        /* Offset 122 days, which is where the magic arithmetic
         * month formula sequence starts (March 1 = 4 * 30.6 = 122.4).
         */
        d = Math.floor((100 * c - 12210) / 36525);
        /* Days in that many whole Julian years */
        x = Math.floor((36525 * d) / 100);

        /* Find month and day. */
        y = Math.floor(((c - x) * 100) / 3061);
        day = Math.floor(c - x - Math.floor((306 * y) / 10));
        month = Math.floor(y - 1);
        if (y > 13) {
            month -= 12;
        }

        /* Get the year right. */
        year = d - 4715;
        if (month > 2) {
            year -= 1;
        }

        /* Fractional part of day. */
        dd = day + julianDate - jd + 0.5;

        if (BC) {
            year = year - 1;
        }

        return [year, month, Math.floor(dd)];
    }

    public onPreRender(frameNumber: number, timeDelta: number) {
        const currentDate = this._startJD + this._stepJD * frameNumber;
        var bodies = Ephemeris.getBodiesPolar(currentDate);
        screenUniforms.uSolar.value = [
            bodies[0].Lon * -1 + Math.PI / 2,
            bodies[1].Lon * -1 + Math.PI / 2,
            bodies[2].Lon * -1 + Math.PI / 2,
            bodies[3].Lon * -1 + Math.PI / 2,
            bodies[4].Lon * -1 + Math.PI / 2,
            bodies[5].Lon * -1 + Math.PI / 2,
            bodies[6].Lon * -1 + Math.PI / 2,
            bodies[7].Lon * -1 + Math.PI / 2,
            bodies[8].Lon * -1 + Math.PI / 2,
        ];

        const gd = this.toGregorian(currentDate);
        this.dayText.text = String(gd[2]).padStart(2, "0");
        this.monthText.text = months[gd[1] - 1];
        this.yearText.text = gd[0].toString();

        this.dayText.sync();
        this.monthText.sync();
        this.yearText.sync();

    }

    public onResize(width: number, height: number) { }


}