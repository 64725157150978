export const moonlat = {
	maxargs: 14,
	max_harmonic: [0, 26, 29, 8, 3, 5, 0, 0, 0, 6, 5, 3, 5, 1, 0, 0, 0, 0],
	max_power_of_t: 3,
	distance: 2.5735686895300000e-03,
	timescale: 3.6525000000000000e+06,
	trunclvl: 1.0000000000000000e-04,
	lon_tbl: [
		-3, -4,
		4, -1856, 0, 8043,
		-9, -1082,
		-1, -310,
		-1, -522,
		-330, -1449, -853, 4656,
		-66, 7,
		-1, 9996928,
		-66, 6,
		23, 183,
		0, 173,
		0, -56,
		0, 50,
		0, -785,
		1, 51,
		0, -60,
		1, 11843, 0, -50754,
		0, 1834, 1, -7910,
		0, -48060,
		1, 56,
		0, 13141, -1, -56318,
		0, 2541,
		-1, -649,
		-133, 778,
		-46, 8,
		1, 1665737,
		-47, 7,
		0, 65,
		0, 45,
		0, -138,
		0, -1005,
		0, -2911,
		0, -47,
		0, 96,
		0, -394,
		2, 76,
		2, -17302, 0, 74337,
		0, -101,
		0, 58,
		0, -171,
		0, -77,
		0, -1283, 0, 2686,
		0, -55,
		0, 99,
		0, 55,
		0, 397,
		0, 540,
		0, 626,
		-1, -5188, 0, 10857,
		0, -216,
		-2, -123,
		0, 6337,
		2, 224,
		-152, -23472, -29, -74336, 0, 295775,
		-20, 149,
		-2, 84,
		9, 304,
		0, -3051,
		-70, -6,
		-57, 34,
		0, -638,
		0, -201,
		-73, 9,
		0, -100,
		-101, -8,
		0, -57,
		0, -207,
		-3, 80,
		-45, 45,
		-5, 102,
		-59, -23,
		52, 201,
		-48, 233, -220, 71,
		4, 2810, 0, 6236541,
		-61, 218, -216, 67,
		51, 201,
		-59, -23,
		-144, -837, -457, 3029,
		-45, 42,
		-15, 73,
		-6, -169,
		0, 135,
		-64, -7,
		0, -16245,
		0, -81,
		-74, -10,
		0, 702, 0, -3013,
		0, -5889,
		1, 141,
		58, 9598, 12, 30443, 1, -120946,
		-1, -84,
		-2, 11246, -1, -48391,
		0, 1393,
		0, 200,
		-136, -17,
		0, 558,
		-64, -8,
		0, -71,
		0, 317577,
		-28, 183,
		1, 219,
		0, 421,
		0, -133,
		501, -139,
		3, 354,
		-101, -13,
		74, 7,
		144, -84,
		59, -2,
		1, 64,
		-2931, 12559, -4641, 2638, -303, -2058,
		-13, -100, -123, -79,
		-19214, 6084, 1494, 26993, 15213, -82219,
		42, 52, 48, -101,
		-53, -4,
		4, 47,
		58, -131,
		46, 14,
		-21, -6,
		-1311, -8791, 10198, -4185, 2815, 5640,
		167, 422, -229, 83,
		3140, 39, 1221, 120, 96, -30,
		-1, 184612405,
		187, 416, -226, 81,
		-1985, -10083, 9983, -4464, 2807, 5643,
		-21, -9,
		113, -367,
		120, 580, -667, 27,
		8, 66,
		-56, -6,
		337, 95,
		-87, 3303,
		-1, 65,
		68, -374,
		0, -574,
		15, -94,
		0, -53,
		0, -1303,
		0, -236,
		283, 36,
		-1, -54,
		269, -35,
		0, -83,
		0, -52,
		0, 730, 0, -3129,
		0, 813,
		0, -4299,
		1, 59,
		-6, 5130, 1, 16239, -1, -64603,
		0, -80,
		91, 12,
		0, -561,
		133, -17,
		0, 250,
		-12, 71,
		0, 155664,
		82, -11,
		0, 106,
		0, -604,
		0, 21862,
		55, -7,
		0, -1514, 0, 6501,
		0, 906,
		0, -68,
		0, 241,
		0, 366,
		0, 70,
		0, -1382, 0, 5957,
		0, 113,
		0, -51,
		0, -55,
		0, 731,
		0, -264,
		0, 65788,
		1, -1504, 0, 3147,
		0, 217,
		0, -4105, 0, 17658,
		1, 69,
		0, -3518,
		0, -1767,
		-43, -7044, -10, -22304, 0, 88685,
		3, 91,
		0, -485,
		0, -57,
		-1, 333548,
		-24, 172,
		11, 544, 1, -1132,
		0, 353,
		0, -188,
		0, 53,
		0, 77,
		158, -887,
		35, 131,
		-54, 13,
		0, 1994821,
		-53, 14,
		36, 125,
		2, 56,
		0, -243,
		0, -364,
		-2, 1916, 0, -8227,
		0, 15700, -1, -67308,
		1, 66,
		0, -53686,
		1, 3058, 1, -13177,
		0, -72,
		0, -72,
		0, 61,
		0, 15812,
		0, 165,
		8, -96,
		318, 1341, 803, -4252,
		24, 193,
		1137, -226, 310, 622,
		-56, 30,
		-3, 10101666,
		-56, 30,
		1096, -225, 300, 600,
		-31, 409,
		-1, -507,
		0, -287,
		0, -1869, 0, 8026,
		1, 544, -1, -1133,
		0, 27984,
		0, -62,
		0, -249,
		0, 187,
		0, -1096,
		1, 53,
		2, 12388, 0, -53107,
		0, -322,
		0, -94,
		0, 15157,
		0, -582,
		0, 3291,
		0, 565,
		0, 106,
		0, 112,
		0, 306,
		0, 809,
		0, 130,
		0, -961, 0, 4149,
		0, 174,
		0, -105,
		0, 2196,
		0, 59,
		0, 36737,
		-1, -1832, 0, 3835,
		0, -139,
		0, 24138,
		0, 1325,
		1, 64,
		0, -361,
		0, -1162,
		-44, -6320, -10, -20003, 0, 79588,
		2, 80,
		0, -2059,
		0, -304,
		0, 21460,
		0, -166,
		0, -87,
		89, -493,
		32, 114,
		34, 510, 1, 1172616,
		31, 113,
		-1, 57,
		0, 214,
		0, -656,
		0, -646,
		0, 1850, 0, -7931,
		0, -6674,
		0, 2944, 0, -12641,
		0, 916,
		45, -255,
		16, 60,
		-1, 619116,
		16, 57,
		0, -58,
		0, 1045,
		0, -156,
		-15, 88,
		0, -62964,
		0, -126,
		0, 1490, 0, -6387,
		0, 119,
		0, 1338,
		0, -56,
		0, 204,
		0, 153,
		0, 940,
		0, 251,
		0, 312,
		0, 584,
		0, -786, 0, 3388,
		0, -52,
		0, 4733,
		0, 618,
		0, 29982,
		0, 101,
		0, -174,
		0, -2637, 0, 11345,
		0, -284,
		0, -524,
		0, -121,
		0, 1464,
		11, -60,
		-1, 151205,
		0, 139,
		0, -2448,
		0, -51,
		0, -768,
		0, -638,
		0, 552, 0, -2370,
		0, 70,
		0, 64,
		0, 57,
		0, 39840,
		0, 104,
		0, -10194,
		0, -635,
		0, 69,
		0, 113,
		0, 67,
		0, 96,
		0, 367,
		0, 134,
		0, 596,
		0, 63,
		0, 1622,
		0, 483,
		0, 72,
		0, 11917,
		0, -63,
		0, 1273,
		0, -66,
		0, -262,
		0, -97,
		0, 103,
		0, 15196,
		0, -1445,
		0, -66,
		0, -55,
		0, -323,
		0, 2632,
		0, -1179,
		0, 59,
		0, -56,
		0, 78,
		0, 65,
		0, 422,
		0, 309,
		0, 2125,
		0, -66,
		0, 124,
		0, -57,
		0, 1379,
		0, -304,
		0, 177,
		0, -118,
		0, 146,
		0, 283,
		0, 119
	],
	lat_tbl: [],
	rad_tbl: [],
	arg_tbl: [
		0, 1,
		3, 1, 10, 1, 12, -1, 11, 1,
		4, 2, 10, 2, 12, -1, 13, -1, 11, 0,
		5, 2, 10, -1, 13, -1, 11, 3, 2, -3, 3, 0,
		5, 2, 10, -1, 13, -1, 11, 2, 3, -2, 5, 0,
		2, -1, 13, 1, 14, 1,
		5, -1, 13, 1, 11, 4, 3, -8, 4, 3, 5, 0,
		2, 1, 13, -1, 11, 0,
		5, 1, 13, -1, 11, 4, 3, -8, 4, 3, 5, 0,
		5, 2, 10, -1, 13, -1, 11, 2, 3, -3, 5, 0,
		4, 1, 10, 1, 12, -2, 13, 1, 11, 0,
		4, 1, 13, -1, 11, 1, 2, -1, 3, 0,
		5, 2, 10, -1, 13, -1, 11, 2, 2, -2, 3, 0,
		3, 1, 10, -2, 13, 1, 11, 0,
		4, 1, 13, -1, 11, 1, 3, -1, 5, 0,
		4, -1, 13, 1, 11, 1, 2, -1, 3, 0,
		3, 1, 12, 1, 13, -1, 11, 1,
		4, 2, 10, 1, 12, -1, 13, -1, 11, 1,
		2, 1, 10, -1, 11, 0,
		4, -1, 13, 1, 11, 1, 3, -1, 5, 0,
		3, 1, 12, -1, 13, 1, 11, 1,
		3, 2, 10, -3, 13, 1, 11, 0,
		3, 2, 12, 1, 13, -1, 11, 0,
		3, -2, 10, 1, 13, 1, 14, 0,
		6, -2, 10, 1, 13, 1, 11, 4, 3, -8, 4, 3, 5, 0,
		3, 2, 10, -1, 13, -1, 11, 0,
		6, 2, 10, -1, 13, -1, 11, 4, 3, -8, 4, 3, 5, 0,
		4, -1, 13, 1, 11, 2, 3, -2, 5, 0,
		4, -1, 13, 1, 11, 3, 2, -3, 3, 0,
		3, 1, 10, -1, 12, -1, 11, 0,
		3, 2, 12, -1, 13, 1, 11, 0,
		3, 2, 10, 1, 13, -3, 11, 0,
		5, -2, 10, 1, 13, 1, 11, 1, 2, -1, 3, 0,
		4, 2, 10, -1, 12, -3, 13, 1, 11, 0,
		3, 3, 10, -2, 13, -1, 11, 0,
		5, -2, 10, 1, 13, 1, 11, 1, 3, -1, 5, 0,
		4, 2, 10, -1, 12, -1, 13, -1, 11, 1,
		2, 3, 10, -3, 11, 0,
		5, -2, 10, 1, 13, 1, 11, 2, 2, -2, 3, 0,
		4, 2, 10, -1, 12, 1, 13, -3, 11, 0,
		3, 4, 10, -3, 13, -1, 11, 0,
		4, 2, 10, -2, 12, -1, 13, -1, 11, 1,
		3, 4, 10, -1, 13, -3, 11, 0,
		4, 2, 10, -3, 12, -1, 13, -1, 11, 0,
		3, 4, 10, -1, 12, -3, 11, 0,
		3, 2, 10, -3, 12, -1, 11, 0,
		4, 4, 10, -1, 12, -2, 13, -1, 11, 0,
		2, 4, 10, -3, 11, 0,
		3, 2, 10, -2, 12, -1, 11, 1,
		4, 3, 10, -1, 12, -1, 13, -1, 11, 0,
		4, -2, 10, 1, 11, 2, 3, -2, 5, 0,
		3, 4, 10, -2, 13, -1, 11, 0,
		4, -2, 10, 1, 11, 2, 2, -2, 3, 0,
		3, 2, 10, -1, 12, -1, 11, 2,
		3, -2, 10, 1, 12, 1, 14, 0,
		4, -2, 10, 1, 11, 2, 3, -2, 4, 0,
		4, -2, 10, 1, 11, 1, 3, -1, 5, 0,
		3, 3, 10, -1, 13, -1, 11, 0,
		4, -2, 10, 1, 11, 3, 2, -4, 3, 0,
		4, -2, 10, 1, 11, 1, 3, -2, 5, 0,
		4, 2, 10, -1, 12, -2, 13, 1, 11, 0,
		4, -2, 10, 1, 11, 1, 2, -1, 3, 0,
		2, -1, 10, 1, 2, 0,
		3, 2, 10, 2, 13, -3, 11, 0,
		4, -2, 10, 1, 11, 2, 2, -3, 3, 0,
		3, 2, 12, -2, 13, 1, 11, 0,
		4, 1, 10, -1, 12, 1, 13, -1, 11, 0,
		3, -2, 10, 1, 11, 1, 5, 0,
		4, 2, 10, -1, 11, 1, 3, -2, 4, 0,
		3, 2, 10, -2, 11, 1, 14, 0,
		4, -2, 10, 1, 11, 8, 2, -13, 3, 0,
		5, -2, 10, -1, 13, 1, 11, 18, 2, -16, 3, 0,
		5, 2, 10, -1, 11, 4, 3, -8, 4, 3, 5, 1,
		2, 2, 10, -1, 11, 1,
		5, -2, 10, 1, 11, 4, 3, -8, 4, 3, 5, 1,
		5, 2, 10, -1, 13, -1, 11, 18, 2, -16, 3, 0,
		4, 2, 10, -1, 11, 8, 2, -13, 3, 0,
		2, -2, 10, 1, 14, 1,
		4, -2, 10, 1, 11, 1, 3, -2, 4, 0,
		3, 2, 10, -1, 11, 1, 5, 0,
		2, 2, 12, -1, 11, 0,
		4, 3, 10, 1, 12, -1, 13, -1, 11, 0,
		4, 2, 10, -1, 11, 2, 2, -3, 3, 0,
		3, 2, 10, -2, 13, 1, 11, 0,
		4, 2, 10, -1, 11, 1, 2, -1, 3, 0,
		3, 1, 10, 1, 2, -2, 3, 0,
		3, 1, 12, -2, 13, 1, 11, 1,
		3, 1, 10, 1, 13, -1, 11, 0,
		4, 2, 10, -1, 11, 1, 3, -1, 5, 0,
		3, 2, 10, 1, 12, -1, 11, 2,
		3, -2, 10, -1, 12, 1, 14, 0,
		2, 1, 12, -1, 11, 1,
		3, 1, 10, -1, 13, 1, 11, 0,
		4, 2, 10, -1, 11, 2, 2, -2, 3, 0,
		3, 1, 10, 2, 2, -3, 3, 0,
		4, 2, 10, 1, 12, -2, 13, 1, 11, 0,
		3, -1, 10, 1, 2, -2, 3, 0,
		3, -1, 11, 1, 2, -1, 3, 0,
		2, 2, 13, -1, 11, 0,
		2, -2, 13, 1, 14, 0,
		4, 2, 10, -1, 11, 2, 3, -2, 5, 0,
		4, 2, 10, -1, 11, 3, 2, -3, 3, 0,
		4, 2, 10, 2, 12, -2, 13, -1, 11, 0,
		3, 1, 10, 1, 3, -2, 5, 0,
		4, 1, 10, 1, 12, 1, 13, -1, 11, 0,
		3, 1, 10, 3, 2, -4, 3, 0,
		3, 1, 10, 1, 3, -1, 5, 0,
		3, 1, 10, 1, 3, -2, 6, 0,
		3, 1, 10, 2, 3, -2, 4, 0,
		4, 1, 10, 1, 12, -1, 13, -1, 11, 0,
		3, 2, 10, 2, 12, -1, 11, 2,
		4, 1, 10, 1, 3, 2, 5, -5, 6, 1,
		1, 1, 14, 2,
		3, 1, 10, 8, 2, -12, 3, 1,
		5, -2, 10, 1, 13, -1, 11, 20, 2, -21, 3, 0,
		5, 2, 10, -2, 13, 1, 11, 2, 3, -3, 5, 0,
		3, 1, 10, 1, 3, 1, 6, 0,
		4, -1, 13, -1, 11, 26, 2, -29, 3, 0,
		3, -1, 11, 8, 2, -13, 3, 0,
		4, -1, 13, -1, 11, 18, 2, -16, 3, 2,
		4, -1, 13, 1, 11, 10, 2, -3, 3, 1,
		1, 1, 11, 3,
		4, -1, 13, -1, 11, 10, 2, -3, 3, 1,
		4, -1, 13, 1, 11, 18, 2, -16, 3, 2,
		3, 1, 11, 8, 2, -13, 3, 0,
		2, 1, 10, 2, 4, 0,
		4, 2, 10, -1, 11, 5, 2, -6, 3, 1,
		5, 2, 10, -2, 13, -1, 11, 2, 3, -3, 5, 0,
		5, -2, 10, 1, 13, 1, 11, 20, 2, -21, 3, 0,
		3, 1, 10, 1, 3, 1, 5, 0,
		2, -2, 11, 1, 14, 0,
		5, 2, 10, -2, 13, 1, 11, 2, 3, -2, 5, 0,
		3, 1, 10, 5, 2, -7, 3, 0,
		4, 1, 10, 1, 12, -1, 13, 1, 11, 0,
		3, 1, 10, 2, 2, -2, 3, 0,
		4, 2, 10, 2, 12, -2, 13, 1, 11, 0,
		2, 2, 13, -3, 11, 0,
		4, 2, 10, -1, 11, 4, 2, -4, 3, 0,
		3, 1, 10, 4, 2, -5, 3, 0,
		3, 1, 10, -3, 13, 1, 11, 0,
		2, 1, 10, 1, 2, 0,
		3, 1, 11, 1, 2, -1, 3, 0,
		4, 2, 10, -1, 11, 3, 3, -3, 5, 0,
		3, 1, 12, 2, 13, -1, 11, 1,
		4, 2, 10, 1, 12, -2, 13, -1, 11, 0,
		3, 1, 10, -1, 13, -1, 11, 0,
		3, 1, 11, 1, 3, -1, 5, 0,
		2, 1, 12, 1, 11, 2,
		4, 2, 10, -1, 11, 5, 2, -5, 3, 0,
		3, 1, 10, 5, 2, -6, 3, 0,
		3, 2, 10, 1, 12, -3, 11, 0,
		3, 1, 10, 2, 2, -1, 3, 0,
		3, 2, 10, -4, 13, 1, 11, 0,
		3, -2, 10, 2, 13, 1, 14, 0,
		3, 2, 10, -2, 13, -1, 11, 0,
		3, 1, 10, 3, 2, -2, 3, 0,
		4, 1, 10, -1, 12, -1, 13, -1, 11, 0,
		2, 2, 12, 1, 11, 0,
		2, 2, 10, -3, 11, 0,
		3, 1, 10, 4, 2, -3, 3, 0,
		4, 2, 10, -1, 12, -2, 13, -1, 11, 1,
		3, 2, 10, -1, 12, -3, 11, 0,
		3, 4, 10, -4, 13, -1, 11, 0,
		4, 2, 10, -2, 12, -2, 13, -1, 11, 0,
		4, 4, 10, -2, 12, -1, 13, -1, 11, 0,
		3, 6, 10, -3, 13, -1, 11, 0,
		4, 4, 10, -1, 12, -1, 13, -1, 11, 1,
		4, 2, 10, -3, 12, -1, 13, 1, 11, 0,
		3, 5, 10, -2, 13, -1, 11, 0,
		3, 4, 10, 1, 13, -3, 11, 0,
		4, 2, 10, -2, 12, 1, 13, -1, 11, 0,
		3, 3, 10, -1, 12, -1, 11, 0,
		3, 4, 10, -1, 13, -1, 11, 0,
		4, 2, 10, -2, 12, -1, 13, 1, 11, 1,
		3, 4, 10, -3, 13, 1, 11, 0,
		4, 2, 10, -1, 12, 1, 13, -1, 11, 1,
		5, -2, 10, 1, 13, -1, 11, 2, 2, -2, 3, 0,
		2, 3, 10, -1, 11, 0,
		4, 4, 10, 1, 12, -1, 13, -1, 11, 0,
		4, 2, 10, -1, 12, -1, 13, 1, 11, 2,
		5, -2, 10, 1, 13, -1, 11, 1, 3, -1, 5, 0,
		3, 3, 10, -2, 13, 1, 11, 0,
		5, -2, 10, 1, 13, -1, 11, 1, 2, -1, 3, 0,
		3, 2, 10, 1, 13, -1, 11, 0,
		3, -2, 10, -1, 13, 1, 14, 0,
		3, 2, 12, -1, 13, -1, 11, 1,
		3, 3, 10, 1, 12, -1, 11, 0,
		3, 1, 10, -1, 12, 1, 11, 0,
		4, -1, 13, -1, 11, 3, 2, -3, 3, 0,
		4, -1, 13, -1, 11, 2, 3, -2, 5, 0,
		3, 2, 10, -1, 13, 1, 14, 0,
		4, -2, 10, -1, 11, 18, 2, -16, 3, 0,
		6, 2, 10, -1, 13, 1, 11, 4, 3, -8, 4, 3, 5, 0,
		3, 2, 10, -1, 13, 1, 11, 0,
		6, -2, 10, 1, 13, -1, 11, 4, 3, -8, 4, 3, 5, 0,
		5, 2, 10, -2, 13, 1, 11, 18, 2, -16, 3, 0,
		4, -2, 10, 1, 13, -2, 11, 1, 14, 0,
		3, 1, 12, -3, 13, 1, 11, 0,
		3, 1, 10, 2, 13, -1, 11, 0,
		4, 2, 10, 1, 12, 1, 13, -1, 11, 1,
		3, 1, 12, -1, 13, -1, 11, 1,
		4, -1, 13, -1, 11, 1, 3, -1, 5, 0,
		2, 1, 10, 1, 11, 0,
		4, 2, 10, 1, 12, -1, 13, 1, 11, 1,
		3, 1, 12, 1, 13, -3, 11, 0,
		4, -1, 13, -1, 11, 1, 2, -1, 3, 0,
		5, 2, 10, -1, 13, 1, 11, 2, 2, -2, 3, 0,
		2, 3, 13, -1, 11, 0,
		4, 1, 10, 1, 12, -2, 13, -1, 11, 0,
		4, 2, 10, 2, 12, 1, 13, -1, 11, 0,
		2, 1, 13, 1, 14, 1,
		5, 2, 10, -1, 13, 1, 11, 2, 3, -3, 5, 0,
		4, -2, 13, -1, 11, 18, 2, -16, 3, 1,
		5, 1, 13, 1, 11, 4, 3, -8, 4, 3, 5, 0,
		2, 1, 13, 1, 11, 0,
		5, -1, 13, -1, 11, 4, 3, -8, 4, 3, 5, 0,
		3, 1, 11, 18, 2, -16, 3, 1,
		3, -1, 13, -2, 11, 1, 14, 0,
		5, 2, 10, -1, 13, 1, 11, 2, 3, -2, 5, 0,
		5, 2, 10, -1, 13, 1, 11, 3, 2, -3, 3, 0,
		3, 1, 10, 1, 12, 1, 11, 1,
		4, 2, 10, 2, 12, -1, 13, 1, 11, 1,
		2, 1, 13, -3, 11, 0,
		4, 1, 13, 1, 11, 1, 2, -1, 3, 0,
		3, 1, 12, 3, 13, -1, 11, 0,
		4, 2, 10, 1, 12, -3, 13, -1, 11, 0,
		3, 1, 10, -2, 13, -1, 11, 0,
		4, 1, 13, 1, 11, 1, 3, -1, 5, 0,
		3, 1, 12, 1, 13, 1, 11, 1,
		2, 1, 10, -3, 11, 0,
		3, 1, 12, -1, 13, 3, 11, 0,
		3, 2, 10, -3, 13, -1, 11, 0,
		3, 2, 12, 1, 13, 1, 11, 0,
		3, 2, 10, -1, 13, -3, 11, 0,
		4, 2, 10, -1, 12, -3, 13, -1, 11, 0,
		4, 2, 10, -1, 12, -1, 13, -3, 11, 0,
		4, 6, 10, -1, 12, -2, 13, -1, 11, 0,
		3, 4, 10, -2, 12, -1, 11, 0,
		3, 6, 10, -2, 13, -1, 11, 0,
		4, 4, 10, -2, 12, -2, 13, 1, 11, 0,
		3, 4, 10, -1, 12, -1, 11, 1,
		3, 2, 10, -3, 12, 1, 11, 0,
		3, 5, 10, -1, 13, -1, 11, 0,
		4, 4, 10, -1, 12, -2, 13, 1, 11, 0,
		4, 2, 10, -2, 12, 2, 13, -1, 11, 0,
		2, 4, 10, -1, 11, 0,
		3, 2, 10, -2, 12, 1, 11, 1,
		4, 3, 10, -1, 12, -1, 13, 1, 11, 0,
		3, 4, 10, -2, 13, 1, 11, 0,
		4, 2, 10, -1, 12, 2, 13, -1, 11, 0,
		4, -2, 10, -1, 11, 2, 2, -2, 3, 0,
		3, 3, 10, 1, 13, -1, 11, 0,
		3, 4, 10, 1, 12, -1, 11, 0,
		3, 2, 10, -1, 12, 1, 11, 2,
		4, -2, 10, -1, 11, 1, 3, -1, 5, 0,
		3, 3, 10, -1, 13, 1, 11, 0,
		4, 4, 10, 1, 12, -2, 13, 1, 11, 0,
		3, 2, 10, 2, 13, -1, 11, 0,
		3, 2, 12, -2, 13, -1, 11, 0,
		4, 1, 10, -1, 12, 1, 13, 1, 11, 0,
		2, 2, 10, 1, 14, 0,
		5, -2, 10, -1, 13, -1, 11, 18, 2, -16, 3, 0,
		2, 2, 10, 1, 11, 1,
		5, 2, 10, -1, 13, 1, 11, 18, 2, -16, 3, 0,
		3, -2, 10, -2, 11, 1, 14, 0,
		4, 3, 10, 1, 12, -1, 13, 1, 11, 0,
		3, 2, 10, -2, 13, 3, 11, 0,
		4, 2, 10, 1, 12, 2, 13, -1, 11, 0,
		3, 1, 12, -2, 13, -1, 11, 1,
		3, 1, 10, 1, 13, 1, 11, 0,
		3, 2, 10, 1, 12, 1, 11, 1,
		2, 4, 13, -1, 11, 0,
		2, 2, 13, 1, 14, 0,
		4, -3, 13, -1, 11, 18, 2, -16, 3, 0,
		2, 2, 13, 1, 11, 0,
		4, 1, 13, 1, 11, 18, 2, -16, 3, 0,
		4, 2, 10, 1, 11, 2, 3, -2, 5, 0,
		4, 1, 10, 1, 12, 1, 13, 1, 11, 0,
		3, 2, 10, 2, 12, 1, 11, 0,
		2, 2, 11, 1, 14, 0,
		1, 3, 11, 0,
		3, 1, 10, -3, 13, -1, 11, 0,
		3, 1, 12, 2, 13, 1, 11, 1,
		2, 1, 12, 3, 11, 0,
		3, 2, 10, -4, 13, -1, 11, 0,
		3, 2, 12, 2, 13, 1, 11, 0,
		3, 2, 10, -2, 13, -3, 11, 0,
		4, 6, 10, -1, 12, -1, 13, -1, 11, 0,
		3, 6, 10, -1, 13, -1, 11, 0,
		4, 4, 10, -2, 12, -1, 13, 1, 11, 0,
		3, 6, 10, -3, 13, 1, 11, 0,
		4, 4, 10, -1, 12, 1, 13, -1, 11, 0,
		4, 4, 10, -1, 12, -1, 13, 1, 11, 1,
		3, 5, 10, -2, 13, 1, 11, 0,
		3, 4, 10, 1, 13, -1, 11, 0,
		4, 2, 10, -2, 12, 1, 13, 1, 11, 0,
		3, 4, 10, -1, 13, 1, 11, 0,
		4, 2, 10, -1, 12, 3, 13, -1, 11, 0,
		4, 4, 10, 1, 12, 1, 13, -1, 11, 0,
		4, 2, 10, -1, 12, 1, 13, 1, 11, 1,
		2, 3, 10, 1, 11, 0,
		4, 4, 10, 1, 12, -1, 13, 1, 11, 0,
		4, 2, 10, -1, 12, -1, 13, 3, 11, 0,
		3, 2, 10, 3, 13, -1, 11, 0,
		3, 2, 10, 1, 13, 1, 14, 0,
		3, 2, 10, 1, 13, 1, 11, 0,
		3, 3, 10, 1, 12, 1, 11, 0,
		3, 2, 10, -1, 13, 3, 11, 0,
		4, 2, 10, 1, 12, 3, 13, -1, 11, 0,
		3, 1, 12, -3, 13, -1, 11, 0,
		3, 1, 10, 2, 13, 1, 11, 0,
		4, 2, 10, 1, 12, 1, 13, 1, 11, 1,
		3, 1, 12, -1, 13, -3, 11, 0,
		2, 1, 10, 3, 11, 0,
		2, 5, 13, -1, 11, 0,
		2, 3, 13, 1, 11, 0,
		4, 1, 10, 1, 12, 2, 13, 1, 11, 0,
		2, 1, 13, 3, 11, 0,
		3, 1, 12, 3, 13, 1, 11, 0,
		3, 1, 12, 1, 13, 3, 11, 0,
		3, 2, 10, -5, 13, -1, 11, 0,
		3, 6, 10, -1, 12, -1, 11, 0,
		4, 6, 10, -1, 12, -2, 13, 1, 11, 0,
		2, 6, 10, -1, 11, 0,
		3, 4, 10, -2, 12, 1, 11, 0,
		3, 6, 10, -2, 13, 1, 11, 0,
		4, 4, 10, -1, 12, 2, 13, -1, 11, 0,
		3, 4, 10, -1, 12, 1, 11, 0,
		3, 4, 10, 2, 13, -1, 11, 0,
		4, 2, 10, -2, 12, 2, 13, 1, 11, 0,
		2, 4, 10, 1, 11, 0,
		3, 4, 10, -2, 13, 3, 11, 0,
		4, 2, 10, -1, 12, 2, 13, 1, 11, 0,
		3, 3, 10, 1, 13, 1, 11, 0,
		3, 4, 10, 1, 12, 1, 11, 0,
		3, 2, 10, -1, 12, 3, 11, 0,
		3, 2, 10, 4, 13, -1, 11, 0,
		3, 2, 10, 2, 13, 1, 11, 0,
		2, 2, 10, 3, 11, 0,
		3, 1, 12, -4, 13, -1, 11, 0,
		3, 1, 10, 3, 13, 1, 11, 0,
		4, 2, 10, 1, 12, 2, 13, 1, 11, 0,
		2, 4, 13, 1, 11, 0,
		2, 2, 13, 3, 11, 0,
		1, 5, 11, 0,
		3, 1, 12, 4, 13, 1, 11, 0,
		4, 6, 10, -1, 12, -1, 13, 1, 11, 0,
		3, 6, 10, 1, 13, -1, 11, 0,
		3, 6, 10, -1, 13, 1, 11, 0,
		4, 4, 10, -1, 12, 1, 13, 1, 11, 0,
		3, 4, 10, 1, 13, 1, 11, 0,
		3, 4, 10, -1, 13, 3, 11, 0,
		4, 2, 10, -1, 12, 3, 13, 1, 11, 0,
		4, 4, 10, 1, 12, 1, 13, 1, 11, 0,
		3, 2, 10, 3, 13, 1, 11, 0,
		3, 2, 10, 1, 13, 3, 11, 0,
		2, 5, 13, 1, 11, 0,
		2, 3, 13, 3, 11, 0,
		2, 6, 10, 1, 11, 0,
		3, 4, 10, 2, 13, 1, 11, 0,
		3, 2, 10, 4, 13, 1, 11, 0,
		-1
	]
};
