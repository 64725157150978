import { Queue } from "./Queue";

export class AngleLPF {
    private sumSin = 0;
    private sumCos = 0;

    private queue: Queue<number> = new Queue<number>();

    public smooth(angle: number): number {

        this.sumSin += Math.sin(angle);
        this.sumCos += Math.cos(angle);

        this.queue.enqueue(angle);

        if (this.queue.size() > 30) {
            var old = this.queue.dequeue();

            this.sumSin -= Math.sin(old);
            this.sumCos -= Math.cos(old);
        }

        return this.average();
    }

    private average(): number {
        var size = this.queue.size();
        return Math.atan2(this.sumSin / size, this.sumCos / size);
    }
}